import React, { useCallback, useState, useEffect } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import useOnClickOutside from "./useOutside";
// import DatePanel from "react-multi-date-picker/plugins/date_panel";

const DatePickerCmp = ({
      id,
      name, 
      dateRangeValue,
      fromDate,
      toDate,
      onChangeDateTimePicker
    }) => {
  const dateTimeRef = React.useRef();

  const handleDatePickerClose = useCallback(
    () => dateTimeRef.current.closeCalendar(),
    [dateTimeRef]
  );

  // if commented this line component TimePicker don't be closed on click anywhere
  useOnClickOutside(dateTimeRef, handleDatePickerClose);

//   const [dates, setDates] = useState([
//     new DateObject(fromDate),
//     new DateObject(toDate),
//  ]);

const [dates, setDates] = useState([]);

 useEffect(() => {

  debugger;
  setDates( [
     new DateObject(fromDate),
      new DateObject(toDate),
   ] );

  // setDates( [
  //  fromDate,
  //  toDate,
  // ] );

}, [fromDate, toDate]);

  return (
    <div>

     {/* fromDate:  {JSON.stringify(fromDate)}
    <br/>
    toDate:  {JSON.stringify(toDate)}
    <br/> */}

    {/* dates: {JSON.stringify(dates)}     */}
    
         <DatePicker
        id= {id}
        name = {name}
        value={fromDate !=null && toDate != null ? dates : "" }
        ref={dateTimeRef}  
        shouldCloseOnSelect
        weekStartDayIndex={1}
        // plugins={[<TimePicker position="bottom" />]}
        range
        numberOfMonths={1}
        rangeHover
        format="DD/MMM/YYYY"
        placeholder=" "
        dateSeparator=" to "
        //onChange={(e) => onChangeDateTimePicker(id, e)}

        onChange={(date) => {

          debugger;

        

          if(date != null)
          {
            const answer_array = date.toString().split(',');
             var FromDate = null;
             var ToDate = null;
    
            if(answer_array !=null && answer_array.length == 1)        
            {
              FromDate = answer_array[0];
              //ToDate = "";

              setDates([FromDate]);
            }
            else if(answer_array !=null && answer_array.length > 1)     
            {

                FromDate = answer_array[0];
                ToDate = answer_array[1];    
                
                setDates([FromDate , ToDate]);
            }

            // debugger;
            // var Frm =  new DateObject(FromDate);
            // var t =  new DateObject(ToDate);

            // setDates([FromDate , ToDate]);
          }
          else
          {
            setDates([]);
          }

          // onChangeDateTimePicker(id,date);
          onChangeDateTimePicker(id,FromDate, ToDate);
         
        }}


        //onChangeHandller = {alert('AA')}
      />
{/* startDate: {startDate} */}
              
      {/* <DatePicker
        multiple
        sort
        onFocusedDateChange={setFocusedDate}
        onClose={() => setFocusedDate(undefined)}
        plugins={[<DatePanel markFocused focusedClassName="bg-red" />]}
        mapDays={({ date, isSameDate }) => {
          let props = {};

          if (!isSameDate(date, focusedDate)) return;

          props.style = { backgroundColor: "red" };

          return props;
        }}
        ref={dateTimeRef}
      /> */}
    </div>
  );
};

export default DatePickerCmp;
