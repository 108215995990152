import React from "react";
import { useState } from "react";
import { Input, Tooltip, Row, Col,  Card, CardBody, CardHeader  } from "reactstrap";
import ButttonTravelNinjaz from "../Common/GloablMaster/ButttonTravelNinjaz";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dragIcon from "../../assets/images/drag.png";

const PackageRatesDynamic = ({
  activeArrowTab,
  cancelHandller,
  handleAddPackageRates,
  packageRatesList,
  modalSaveHandller,      
  onChangePackageRatesInputs,
  removePackageRatesHandller,
  showInvalid,
  toggleArrowTab,
  handleDragEndpackageRates
}) => {
  const [tooltipOpenInclusion, setToolTipOpenInclusion] = useState(false);

  return (
    <>
      <Row>
              <Col lg={12}>
                  <Card>
                    <CardHeader className="align-items-center d-flex bg-light">
                      <h4 className="card-title mb-0">Package Rates</h4>
                    </CardHeader>
                    <CardBody>
                      <Row>
                      <Col xxl={12} className="cardItems">
                        <div>
                          <label htmlFor="add_packageRates" className="form-label">
                            Add Package Rate{" "}
                            <i
                              className="ri-add-line align-bottom mx-2"
                              onClick={handleAddPackageRates}
                              id="package_packageRates"
                              style={{
                                padding: "3px",
                                // marginTop: "10px",
                                fontSize: "14px",
                                borderRadius: "50%",
                                backgroundColor: "#099885",
                                color: "white",
                                cursor: "pointer",
                              }}
                            ></i>
                            <Tooltip
                              isOpen={tooltipOpenInclusion}
                              placement="right"
                              target="package_packageRates"
                              toggle={() => {
                                setToolTipOpenInclusion(!tooltipOpenInclusion);
                              }}
                            >
                              Add Package Rate
                            </Tooltip>
                          </label>
                        </div>
                        <div className="m-3">
             
                          <div className="table-responsive table-card">

                            <DragDropContext onDragEnd={handleDragEndpackageRates}>
                                <table
                                  className="table align-middle table-nowrap"
                                  id="customerTable"
                                >
                                  <thead className="table-light">
                                      <tr>
                                      <th className="" width={50} >Drag</th>
                                      <th className="" width={50} >S.No.</th>
                                      <th className="" width={200}>Description</th>
                                      <th className="" width={100} >Package Rate <br/>
                                        <span className="fs-10">
                                            (Before Discount)
                                        </span> 
                                        </th>
                                      <th className="" width={100} >Package Rate</th>
                                      <th className="" width={100}>Remove</th>
                                    </tr>
                                  </thead>


                                 {/* "packageRatesList"   {JSON.stringify(packageRatesList)} */}

                                  <Droppable droppableId="droppable-1">
                                    {(provider) => (
                                        <tbody
                                        ref={provider.innerRef}
                                        {...provider.droppableProps}
                                         >                                
                                          {packageRatesList?.length > 0 &&
                                            packageRatesList?.map((item, index) => (

                                            <Draggable
                                              key={`dragableId-${index}`}
                                              draggableId={`dragableId-${index}`}
                                              index={index}
                                              type="TASK"
                                            >
                                              {(provider) => (
                                                <tr
                                                  key={index}
                                                  {...provider.draggableProps}
                                                  {...provider.dragHandleProps}
                                                  ref={provider.innerRef}
                                                >
                                                  <td
                                                    {...provider.dragHandleProps}
                                                   
                                                  >
                                                    <img
                                                      src={dragIcon}
                                                      alt="dragIcon"
                                                      style={{
                                                        height: "30px",
                                                        width: "30px",
                                                        opacity: "0.7",
                                                      }}
                                                    />
                                                  </td>
                                                  <td> {index + 1}</td>
                                                  <td>  
                                                    
                                                  <Input
                                                          type="text"
                                                          className="form-control"
                                                          name="packageRateDescription"
                                                          id = {item?.id}
                                                          value={item?.packageRateDescription}
                                                          defaultValue={item?.packageRateDescription}
                                                          onChange={(e) => onChangePackageRatesInputs(e)}
                                                          //invalid={item?.packageRateDescription?.length < 1 && showInvalid}
                                                          //required
                                                        />
                                                         
                                                     
                                                   </td>  
                                                   <td> 
                                                    
                                                      <Input
                                                          type="number"
                                                          className="form-control"
                                                          name="packageRateBeforeDiscount"
                                                          id = {item?.id}
                                                          value={item?.packageRateBeforeDiscount}
                                                          defaultValue={item?.packageRateBeforeDiscount}
                                                          onChange={(e) => onChangePackageRatesInputs(e)}
                                                          //invalid={item?.packagePrice?.length < 1 && showInvalid}
                                                          //required
                                                        />


                                                   </td>
                                                   <td> 
                                                       <Input
                                                          type="number"
                                                          className="form-control"
                                                          name="packageRate"
                                                          id = {item?.id}
                                                          value={item?.packageRate}
                                                          defaultValue={item?.packageRate}
                                                          onChange={(e) => onChangePackageRatesInputs(e)}
                                                          //invalid={item?.packagePrice?.length < 1 && showInvalid}
                                                          //required
                                                        />

                                                   </td>

                                                   <td>
                                                    <i
                                                      className="ri-close-line"
                                                      onClick={() => removePackageRatesHandller(item.id)}
                                                      style={{
                                                        fontSize: "25px",
                                                        cursor: "pointer",
                                                      }}
                                                    ></i>
                                                  </td>
                                                </tr>
                                              )}
                                             </Draggable>
                                           ))}
                                          {provider.placeholder}
                                        </tbody>
                                      )}
                                    </Droppable>
                                  </table>
                            </DragDropContext>

                          </div>
                        </div>
                      </Col>
                      </Row>
                    </CardBody>
                  </Card>
              </Col>             
      </Row>       
   

      <div className="d-flex justify-content-xl-between">
        <div>
          <button
            type="button"
            className="btn btn-light btn-label previestab"
            onClick={() => {
              toggleArrowTab(activeArrowTab - 1);
            }}
          >
            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
            Back
          </button>
        </div>
        <div>
          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
            className="mx-1"
          />
          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
            className="mx-1"
          />
        </div>
      </div>
    </>
  );
};

export default PackageRatesDynamic;
