import React, { useEffect, useContext} from "react";
import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";

import BreadCrumb from "../../components/Common/BreadCrumb";
import classnames from "classnames";
import HeroImages from "../../components/home/settings/HeroImages";


const Settings = () => {

  // document.title = "CitySiteSeeing | TravelNinjaz B2B";
  document.title = "Home Settings"
  
  
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  
    /**This is for form wizard tabs*/
    const [activeArrowTab, setactiveArrowTab] = useState(4);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    function toggleArrowTab(tab) {
      if (activeArrowTab !== tab) {
        var modifiedSteps = [...passedarrowSteps, tab];
        if (tab >= 1 && tab <= 13) {
          setactiveArrowTab(tab);
          setPassedarrowSteps(modifiedSteps);
        }
      }
    }
  

    const [selectedCoverImage, setSelectedCoverImage] = useState([]);
    const [selectedProfileImage, setSelectedProfileImage] = useState([]);    
    
    const [savedCoverImage, setSavedCoverImage] = useState(null);
    const [savedProfileImage, setSavedProfileImage] = useState(null);

    function handleAcceptedCoverImage(files) {

      setSelectedCoverImage(null);    
  
       //setIsNullChild({ ...isNullChild, imagelistNullable: true });
       var file = files ? files[0] : null;

        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        });
      setSelectedCoverImage(file);
    }
    
    function handleAcceptedProfileImage(files) {

      setSelectedProfileImage(null);    
  
       //setIsNullChild({ ...isNullChild, imagelistNullable: true });
       var file = files ? files[0] : null;

        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        });
        setSelectedProfileImage(file);
    }


  const removeSelectedCoverImage = () => {
    setSelectedCoverImage(null);
  };

  const removeSelectedProfileImage = () => {
    setSelectedProfileImage(null);
  };

  
  const removeSavedCoverImage = () => {
    setSavedCoverImage(null);
  };

  const removeSavedProfileImage = () => {
    setSavedProfileImage(null);
  };

    /**Cancel handller */
    const cancelHandller = (e) => {
      e.preventDefault();
    };

      /**Save Settings handller */
  const saveHandller = async (event) => {
    event.preventDefault();

  };


  //--------------------------------------
  return (
    <>
      <div className="page-content">
          <Container fluid>
              <BreadCrumb
                title="Home Settings"
                isSubTitle={false}
              />          
          </Container>

          <Row>
            <Col xl={12}>
            <Card style={{ minHeight: "75vh" }}>
              <CardBody className="form-steps">
                <Form>
                  {/* <div className="step-arrow-nav mb-4"> */}
                  <div className="mb-4">

                  <Row>

                  <Col lg={2}>
                  <Nav
                      // className="nav-pills custom-nav nav-justified"
                      className="nav nav-pills flex-column nav-pills-tab custom-verti-nav-pills text-center"
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          href="#"
                          id="steparrow-gen-info-tab"
                          className={classnames({
                            active: activeArrowTab === 1,
                            done: 1 ,
                          })}
                          onClick={() => {
                            toggleArrowTab(1);
                          }}
                        >
                          Hero Image
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          id="steparrow-gen-info-tab"
                          className={classnames({
                            active: activeArrowTab === 2,
                            done: 2 ,
                          })}
                          onClick={() => {
                            toggleArrowTab(2);
                          }}
                        >
                          Seasonal Destinations
                        </NavLink>
                      </NavItem>                   
                    
                    </Nav>

                  </Col>

                  <Col lg={10}>
                  <TabContent activeTab={activeArrowTab}>
                    <TabPane id="steparrow-destination-info" tabId={1}>
                        <HeroImages
                        
                        toggleArrowTab={toggleArrowTab}
                        activeArrowTab={activeArrowTab}
                        cancelHandller={cancelHandller}
                        saveHandller={saveHandller}
                                                               
                        handleAcceptedCoverImage = {handleAcceptedCoverImage}
                        handleAcceptedProfileImage = {handleAcceptedProfileImage}
         
                        selectedCoverImage = {selectedCoverImage}
                        selectedProfileImage = {selectedProfileImage}
             
                        savedCoverImage = {savedCoverImage}
                        savedProfileImage = {savedProfileImage} 
                 
                        removeSelectedCoverImage ={removeSelectedCoverImage}
                        removeSelectedProfileImage ={removeSelectedProfileImage}
                              
                        removeSavedCoverImage ={removeSavedCoverImage}
                        removeSavedProfileImage ={removeSavedProfileImage}
                     />
                    
                    </TabPane>

                    <TabPane id="steparrow-cityandnight-info" tabId={2}>
                    <h3> Tab 2</h3>
                    </TabPane>
           
                  </TabContent>
                  </Col>
                  </Row>
                  
                  </div>

              
                </Form>
              </CardBody>
            </Card>
            </Col>
          </Row>
       </div>
    </>
  );
};

export default Settings;
