import { Image, Space, Table } from "antd";
import React, { useRef } from "react";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import ButttonTravelNinjaz from "../../components/Common/GloablMaster/ButttonTravelNinjaz";
import Select from "react-select";
import classnames from "classnames";
import makeAnimated from "react-select/animated";
import { useEffect } from "react";
import { GetDestinationList } from "../../services/GlobalMasters/destinationService";
import { GetCityList } from "../../services/GlobalMasters/cityServices";
import { GetTransportRateList } from "../../services/GlobalMasters/transportRateService";
import Export from "../../components/Common/GloablMaster/export/Export";
import {
  errornotify,
  successNotify,
} from "../../components/Common/notification";
import {
  DeletePackageImageList,
  GetPackageList,
  SavePackageList,
  savePackageImageList,
  DuplicatePackage,
  savePackageItineraryFile,
  deletePackageItineraryFile
} from "../../services/Package";
import { url } from "../../services/common/const";
import {
  downloadExcel,
  filterUniqueDestination,
  filterUniqueTransport,
} from "../../functions/downloadtoExcel";
import ColumnVisibleAndHide from "../../components/Common/GloablMaster/columnvisibleAndHide/ColumnVisibleAndHide";
import Mastersearch from "../../components/Common/GloablMaster/Mastersearch";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "../../assets/scss/pages/package.scss";
import DestinationAndPackage from "../../components/package/DestinationAndPackage";
import "../../assets/scss/antdTable/antdTable.scss";
import PackageTransportRate from "../../components/package/PackageTransportRate";
import PackageInclusions from "../../components/package/PackageInclusions";
import PackageImages from "../../components/package/PackageImages";
import PackageItinerary from "../../components/package/PackageItinerary";
import { appName } from "../../common/applicationName";
import { getSessionUsingSessionStorage } from "../../services/common/session";
import PackageSiteSeeing from "../../components/package/PackageSiteSeeing";
import PackageExlusions from "../../components/package/PackageExlusions";
import { getCitySiteSeeingList } from "../../services/GlobalMasters/citySiteSeeingService";
import PackageGuideLines from "../../components/package/PackageGuideLines";
import PackageWhyUs from "../../components/package/PackageWhyUs";
//import PackageDates from "../../components/package/PackageDates";
import PackageRates from "../../components/package/PackageRates";
import PackageFaqComponent from "../../components/package/PackageFaqComponent";
import UseAuth from "../../components/UseAuth";
import { useNavigate } from "react-router-dom";
import { authError } from "../../services/common/const";
import PackageCity from "../../components/package/PackageCity";

import CurrencyShow from "../../common/CurrencyShow";
import CurrencySymbol from "../../common/CurrencySymbol";
import { AlignRight } from "feather-icons-react/build/IconComponents";
import Operation from "antd/lib/transfer/operation";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"


import PackageDatesDynamic from "../../components/package/PackageDatesDynamic";
import PackageRatesDynamic from "../../components/package/PackageRatesDynamic";
import Resizer from "react-image-file-resizer";

const animatedComponents = makeAnimated();
const Packages = () => {
  UseAuth();
  document.title = `${appName}- Package`;
  const [package_id, set_package_id] = useState(0);
  const searchInput = useRef(null);
  const [userName, setUserName] = useState();
  const [dataSourceSiteSeeing, setDataSourceSiteSeeing] = useState([]); /**This is for all */
  const [dataSourceSite, setDataSourceSite] = useState([]); /**This is for only matches destination id */

  const [searchDestinationTypeOption, setSearchDestinationTypeOption] = useState(JSON.parse(localStorage.getItem("global_search_destination_type_option")));
  const [searchDestinationOption, setSearchDestination] = useState( JSON.parse(localStorage.getItem("global_search_destination_option")));
  const currencySymbol = localStorage.getItem("currencySymbol");

  const navigate = useNavigate();

  /** get session details*/

  useEffect(() => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserName(value.userName);
      });
  }, []);
  const [showInvalid, setShowInvalid] = useState(false);
  const [imagePreviewFileName , setImagePreviewFileName] = useState([]);
  //const [packageName, setPackageName] = useState(undefined);
  const [filterColumnWiseTransport, setFilterColumnWiseTransport] = useState(
    []
  );

  const [selectedPackageItineraryFile, setSelectedPackageItineraryFile] = useState([]);
  const [isRemovedPackageItineraryFile, setIsRemovedPackageItineraryFile] = useState(false);


  const [selectedCoverImage, setSelectedCoverImage] = useState([]);
  const [selectedProfileImage, setSelectedProfileImage] = useState([]);
  const [selectedGalleryImages, setSelectedGalleryImages] = useState([]);

  const [selectedAllFiles, setSelectedAllFiles] = useState([]);


  const [savedCoverImage, setSavedCoverImage] = useState(null);
  const [savedProfileImage, setSavedProfileImage] = useState(null);
  const [savedGalleryImages, setSavedGalleryImages] = useState(null);

  //const [getImageFileNames, setGetImageFileNames] = useState([]);
  const [getCoverImageFileName, setGetCoverImageFileName] = useState(null);


  const [deleteImageList, setDeleteImageList] = useState([]);

  const [isPrimaryImage, setIsPrimaryImage] = useState({
    package_image_id: null,
    is_primary: false,
  });

  const [packageItinerary, setPackageItinerary] = useState([
    {
      id: 1,
      day: 1,
      packageItineraryTitle: undefined,
      packageItineraryDescription: undefined,
    },
  ]);

  const [packageFAQ, setPackageFAQ] = useState([
    {
      id: 0,
      packageFaqId: 0,
      packageFaqTitle: undefined,
      packageFaqDescription:undefined,
    },
  ]);

  const { package_image_id, is_primary } = isPrimaryImage;

  const [duplicatePackageId, setDuplicatePackageId] = useState("");
  const [duplicatePackageName, setDuplicatePackageName] = useState("");

        
      
    /**image select */
    //Original
    function handleAcceptedCoverImage(files) {

      setSelectedCoverImage(null);    
  
       setIsNullChild({ ...isNullChild, imagelistNullable: true });
       var file = files ? files[0] : null;

        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        });
      setSelectedCoverImage(file);
    }
    
    function handleAcceptedProfileImage(files) {

      setSelectedProfileImage(null);    
  
       setIsNullChild({ ...isNullChild, imagelistNullable: true });
       var file = files ? files[0] : null;

        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        });
        setSelectedProfileImage(file);
    }
      
      
  /**image select */
  function handleAcceptedGalleryImages(files) {

    debugger;
    setIsNullChild({ ...isNullChild, imagelistNullable: true });
    files.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        is_primary: false,
      });
    });
    setSelectedGalleryImages(files);

    debugger;
  }

  
// On file select (from the pop up)
const onPackageItineraryFileChange = (event) => {

  //alert(event.target.files[0]);
  // Update the state
  setSelectedPackageItineraryFile(event.target.files[0]);
};



  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  /**This state for a check send cityandnights or inclusions send value null or not*/
  const [isNullChild, setIsNullChild] = useState({
    cityAndNightsNullable: false,
    inclusionsNullable: false,
    imagelistNullable: false,
    itineraryNullable: false,
    faqNullable: false,
    packageDatesListNullable: false,
    packageRatesListNullable: false,
  });
  const {
    cityAndNightsNullable,
    inclusionsNullable,
    imagelistNullable,
    itineraryNullable,
    faqNullable,
  } = isNullChild;
  /**This state for read Only and edit only*/
  const [readOnly, setReadOnly] = useState(false);
  /**selection state */
  const [modalValue, setModalValue] = useState({
    destinationType: null,
    destination: null,
    packageTitle: "",
    packageName: "",
    //packageDescription: "",
    packagePrice: "",
    packagePriceBeforeDiscount : "",
    packageRating: 0,
    packageRatingAverage: 0,
    packageCommision: null,   
    transport: null, 
    nights: null,
    is_active: true,
    packageOrderNo: 0,
    fromDate: null,
    // packageGuidelines: "",
    // packageDates : "",
    // packageWhyus: "",
    toDate: null,  
    packageItineraryFile: "",
  });
  const {
    destinationType,
    destination,
    packageTitle,
    packageName,
    //packageDescription,
    packagePrice,
    packagePriceBeforeDiscount,
    packageRating,
    packageRatingAverage,
    packageCommision,
    transport,  
    is_active,
    packageOrderNo,
    packageItineraryFile
  } = modalValue;
  /**This is state for select options*/
  const [selectOptions, setSelectOptions] = useState({
    destinationOptions: [],
   // cityOptions: [],
    transportOptions: [],
  });
  let { destinationOptions
    //, cityOptions, 
    ,transportOptions } = selectOptions;
  const [cityOptions, setCityOptions] = useState([]);

  const [specialCheckBox, setSpecialCheckBox] = useState({
    isbestselling: false,
    ishoneymoonpackage: false,
    isfamilypackage: false,
    isUpcomingPackage: false,
  });
  const [imagePreviewFile, setImagePreviewFile] = useState(undefined);
  const [imageFile, setIamgeFile] = useState();
  const [exportedData, setExportedData] = useState([]);
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [searchTextantd, setSearchTextantd] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [packageDescription, setPackageDescription] = useState("");

  const [packageGuidelines, setPackageGuidelines] = useState("");
  const [packageRates, setPackageRates] = useState("");
  const [packageDates, setPackageDates] = useState("");
  const [packageWhyus , setPackageWhyus ] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const [focusedDate, setFocusedDate] = useState();


  const [modalDuplicatePackage, setModalDuplicatePackage] = useState(false);

  function toggleModalDuplicatePackage(package_id, package_name) {
    setModalDuplicatePackage(!modalDuplicatePackage);
    setDuplicatePackageId(package_id);
    setDuplicatePackageName(package_name);
  }
  

  /*Original Api Data**/
  const [dataSource, setDataSource] = useState({
    destinationList: [],
    //cityList: [],
    transportList: [],
    packageList: [],
  });
  let { destinationList, 
    //cityList,
     transportList, packageList } = dataSource;
  /**This is for hidden/show column*/
  const [switchCheck, setSwitchCheck] = useState({
    createdBy: true,
    createdDate: true,
    modifiedBy: true,
    modifiedDate: true,
  });
  /**This is for over all search */
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState([]);
  /**For filter column wise*/
  const [filterColumnWiseDestination, setFilterColumnWiseDestination] =
    useState([]);
  /**City And Night Add*/
  const [cityAndNight, setCityAndNight] = useState([
    {
      id: 1,
      city: null,
      nights: undefined,
      city_id: null,
    },
  ]);

  const removeSelectedCoverImage = () => {
    setSelectedCoverImage(null);
  };

  const removeSelectedProfileImage = () => {
    setSelectedProfileImage(null);
  };

  /**This is for remove selected image */
  const removeSelectedGalleryImage = (item) => {
    let newArray = selectedGalleryImages.filter((itemImage) => {
      return itemImage.name !== item.name;
    });
    setSelectedGalleryImages(newArray);
  };

  const removeSavedCoverImage = () => {
    setSavedCoverImage(null);
  };

  const removeSavedProfileImage = () => {
    setSavedProfileImage(null);
  };

    /**This is for delete saved database  Gallery images */
    // const onPrimaryImageDeleteHandller = (item) => {
    //   setIsNullChild({ ...isNullChild, imagelistNullable: true });
    //   const removeSavedGalleryFile = savedGalleryImages.filter((itemget, index) => {
    //     return itemget.package_image_id !== item.package_image_id;
    //   });
    //   // setGetCoverImageFileName(removeSavedImages);
    //   savedGalleryImages(removeSavedGalleryFile);
    //   setDeleteImageList([...deleteImageList, item]);
    // };

  /**This is for delete saved database  Gallery images */
  const removeSavedGalleryImage = (item) => {
    // setIsNullChild({ ...isNullChild, imagelistNullable: true });
    // const removeSavedImages = getImageFileNames.filter((itemget, index) => {
    //   return itemget.package_image_id !== item.package_image_id;
    // });
    // setGetImageFileNames(removeSavedImages);
    // setDeleteImageList([...deleteImageList, item]);


    setIsNullChild({ ...isNullChild, imagelistNullable: true });
      const removeSavedGalleryFile = savedGalleryImages.filter((itemget, index) => {
        return itemget.package_image_id !== item.package_image_id;
      });
      // setGetCoverImageFileName(removeSavedImages);
      setSavedGalleryImages(removeSavedGalleryFile);
      setDeleteImageList([...deleteImageList, item]);
      
  };

  /**Inclusions*/
  const [inclusions, setInclusions] = useState([
    {
      id: `${1}-inclusions`,
      inclusions: null,
      is_include: true,
    },
  ]);
  /**Excusions*/
  const [exclusions, setExclusions] = useState([
    {
      id: `${1}-exclusions`,
      inclusions: null,
      is_include: false,
    },
  ]);

    /**Package Dates*/
    const [packageDatesList, setPackageDatesList] = useState([
      {
        id: `${1}-packageDatesList`,
        packageDateRange: null,
        packageFromDate: null,
        packageToDate: null,
        packageRemarks: null,
        packagePrice: null

      },
    ]);

    /**Package Rates*/
    const [packageRatesList, setPackageRatesList] = useState([
      {
        id: `${1}-packageRatesList`,
        packageRateDescription: null,
        packageRateBeforeDiscount: null,
        packageRate: null,
      },
    ]);

        
  // Tooltip Open state
  const [exportTableData, setExportableData] = useState([]);
  /**pacakge description handller */

  /**This is for destination options*/
  if (destinationList?.length > 0) {
    destinationOptions = [];
    const filterDestinationList = destinationList.filter((item) => {
      // return item.destination_type_id === 2 && item.is_active;
      return item.is_active;
    });
    filterDestinationList.map((item, index) => {
      destinationOptions.push({
        label: item.destination_name,
        value: item.destination_id,
      });
    });
  }

  /**Over All table search in master */
  const searchInputHandller = (searchValue) => {
    setSearchText(searchValue);
    if (searchText !== "") {
      const filterData = packageList.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setFilterData(filterData);
      setExportableData(JSON.parse(JSON.stringify(filterData)));
    } else {
      setFilterData(packageList);
      setExportableData(JSON.parse(JSON.stringify(packageList)));
    }
  };

  /**This is for form wizard tabs*/
  const [activeArrowTab, setactiveArrowTab] = useState(4);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
  function toggleArrowTab(tab) {
    if (activeArrowTab !== tab) {
      var modifiedSteps = [...passedarrowSteps, tab];
      if (tab >= 1 && tab <= 13) {
        setactiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  }

  /**Edit Handller*/
  const editHandller = (record) => {
    setactiveArrowTab(1);
    setPassedarrowSteps([1]);
    setImagePreviewFileName(record.package_image);
    //setPackageName(record.package_name);
    setPackageDescription(record.package_description);

    setPackageGuidelines(record?.package_guidelines);
    setPackageDates(record?.package_dates);
    setPackageWhyus(record?.package_whyus);
   
    setSelectedGalleryImages([]);
    setSelectedCoverImage(null);
    setSelectedProfileImage(null);


    setDeleteImageList([]);
    setSelectedPackageItineraryFile(null);
    setIsRemovedPackageItineraryFile(false);

    setModalValue({
      ...modalValue,
      destinationType: {
        label: record.destination_type_name,
        value: record.destination_type_id,
      },
      destination: {
        label: record.destination_name,
        value: record.destination_id,
      },    

      packageCommision: record.package_commision,
      packageName: record.package_name,
      packageTitle: record.package_title,
      packagePrice: record.package_price,
      packagePriceBeforeDiscount: record.package_price_before_discount,
      packageRating: record.package_rating,
      packageRatingAverage: record.package_rating_average,   
      transport: {
        label: record.transport_rate_name,
        value: record.transport_rate_id,
      },    
      is_active: record.is_active,
      packageOrderNo: record.package_order_no,
      packageItineraryFile: record.package_itinerary_file
    });
    setSpecialCheckBox({
      ...specialCheckBox,
      isbestselling: record.is_best_selling,
      ishoneymoonpackage: record.is_honeymoon_package,
      isfamilypackage: record.is_family_package,
      isUpcomingPackage: record.is_upcoming_package      
    });
    set_package_id(record.package_id);
    setImagePreviewFileName(record.package_image);
    let cityAndNightsArray = [];
    record?.packageCityList?.map((item, index) => {
      cityAndNightsArray.push({
        city: {
          label: item.city_name,
          value: item.city_id,
        },
        nights: item?.packageCityHotelList?.[0]?.nights,
        id: index + 1,
        Package_city_id: item.package_city_id,
      });
    });
    setCityAndNight(cityAndNightsArray);
    let inclusionsListArray = [];
    let exclusionsListArray = [];
    let countInclusions = 0;
    let countExclusions = 0;
    record.inclusionsList?.map((item, index) => {
      if (item.is_include) {
        countInclusions = countInclusions + 1;
        inclusionsListArray.push({
          id: `${countInclusions}-inclusions`,
          inclusions: item.inclusions,
          Inclusions_id: item.inclusions_id,
          is_include: item.is_include,
        });
      } else {
        countExclusions = countExclusions + 1;
        exclusionsListArray.push({
          id: `${countExclusions}-exclusions`,
          inclusions: item.inclusions,
          Inclusions_id: item.inclusions_id,
          is_include: item.is_include,
        });
      }
    });
    setInclusions(inclusionsListArray);
    setExclusions(exclusionsListArray);
    /**package itinerary list */
    let packageItineraryArray = [];
    record?.package_itinerary_list?.map((item, index) => {
      packageItineraryArray.push({
        id: index + 1,
        Package_itinerary_id: item?.package_itinerary_id,
        day: item?.day,
        packageItineraryTitle: item?.itinerary_title,
        packageItineraryDescription: item?.itinerary_description,
      });
    });
    setPackageItinerary(packageItineraryArray);

     /**package Faq list */   
     let packageFaqArray = [];
     record?.packageFaqList?.map((item, index) => {
      packageFaqArray.push({
         id: index + 1,
         packageFaqId: item?.package_faq_id,
         id:  item?.package_faq_id,
         packageFaqTitle: item?.faq_title,
         packageFaqDescription: item?.faq_description,
       });   
     });
     setPackageFAQ(packageFaqArray);

      /**package Dates list */   
    let packageDatesArray = [];
    record?.packageDatesList?.map((item, index) => {
      packageDatesArray.push({
        // id: index + 1,     
        id:  item?.package_dates_id,     
        packageDateRange: item?.package_date_range,
        packageFromDateStr: item?.package_from_date_str,
        packageToDateStr: item?.package_to_date_str,

        packageFromDate: item?.package_from_date,
        packageToDate: item?.package_to_date,

        packageRemarks: item?.package_remarks,
        packagePrice: item?.package_price,

      });   
    });
     setPackageDatesList(packageDatesArray);

     // /**package Rates list */   
    let packageRatesArray = [];
    record?.packageRatesList?.map((item, index) => {
      packageRatesArray.push({
        id:  item?.package_rates_id,     
        packageRateDescription: item?.package_rate_description,
        packageRateBeforeDiscount: item?.package_rate_before_discount,
        packageRate: item?.package_rate,

      });   
    });
     setPackageRatesList(packageRatesArray);





      if (record.imageList !== null &&  record.imageList.length >0)
      {
        //let primaryImage =  record.imageList?.filter((elm) => elm.is_primary == 1);
        //let galleryImages =  record.imageList?.filter((elm) => elm.is_primary == 0);
    
        //setGetCoverImageFileName(primaryImage || [] );
        setSavedGalleryImages(record.imageList || []);
      }

      // setSavedCoverImage(record.cover_image_name);
      // setSavedProfileImage(record.Profile_image_name);

      debugger;

      if(record.cover_image_name != null && record.cover_image_name != "")
      {
      setSavedCoverImage({
        package_id: record.package_id,
        image_name: record.cover_image_name,     
      });
    }
      
    if(record.profile_image_name != null && record.profile_image_name != "")
      {
      setSavedProfileImage({
        package_id: record.package_id,
        image_name: record.profile_image_name        
      });
    }
    
     //setSelectedPackageItineraryFile(record.package_itinerary_file);

    setReadOnly(true);
    tog_scroll();
    // console.log(record.package_id);
  };


  /**download to excel */
  const getAllList = async () => {
    try {

        //if (userName) {
        let params = new URLSearchParams();
     
        params.append("UserId", userName);

        let destinationType = JSON.parse(localStorage.getItem("global_search_destination_type_option"));
        let destination =  JSON.parse(localStorage.getItem("global_search_destination_option"));

        if(destinationType != null)
          params.append("destination_type_id", destinationType?.value);

        if(destination != null)
          params.append("destination_id", destination?.value);


        /**destination get api call */
        const destinationResult = await GetDestinationList();
        if (destinationResult?.data?.status === "SUCCESS") {
          setDataSource((prevDataSource) => {
            return {
              ...prevDataSource,
              destinationList: destinationResult.data.data,
            };
          });
        } else {
          throw destinationResult.message;
          // return;
        }
   
       
         
        // if (cityList.length > 0) {
        //   let tempCityOption = [];
        //   cityList
        //     // .filter((item) => {
        //     //   return (
        //     //     item.destination_id === selectedDestinationId && item.is_active
        //     //   );
        //     // })
        //     .map((item, index) => {
        //       tempCityOption.push({
        //         label: item.city_name,
        //         value: item.city_id,
        //       });
        //     });
        //     setCityOptions(tempCityOption);
        // }
        
        /***Transport get api call*/
        const transportResult = await GetTransportRateList();
        if (transportResult?.data?.status === "SUCCESS") {
          setDataSource((prevDataSource) => {
            return {
              ...prevDataSource,
              transportList: transportResult.data.data,
            };
          });
        } else {
          throw transportResult.message;
          // return;
        }

        /***Package get api call*/
        const packageResult = await GetPackageList(params);
        if (packageResult?.data?.status === "SUCCESS") {
          setDataSource((prevDataSource) => {
            // return {
            //   ...prevDataSource,
            //   packageList: packageResult.data.data?.filter((item, index) => {
            //     return item.destination_type_id === 2;
            //   }),
            // };

            return {
              ...prevDataSource,
              packageList: packageResult.data.data
            };

          });
          /**This is for destination filter  */
          let uniqueDestination = filterUniqueDestination(
            packageResult.data.data
          );
          let filterDestination = [];
          uniqueDestination.map((item, index) => {
            filterDestination.push({
              text: item?.destination_name,
              value: item?.destination_id,
            });
          });
          setFilterColumnWiseDestination(filterDestination);
          /**This is for transport filter  */
          let uniqueTransport = filterUniqueTransport(packageResult.data.data);
          let filterTransport = [];
          uniqueTransport.map((item, index) => {
            filterTransport.push({
              text: item?.transport_rate_name,
              value: item?.transport_rate_id,
            });
          });
          setFilterColumnWiseTransport(filterTransport);
          // packageResult.data.data.map((item, index) => {
          //   filterColumnWiseDestination.push({
          //     text: item?.destination_name,
          //     value: item?.destination_id,
          //   });
          //   filterColumnWiseTransport.push({
          //     text: item?.transport_rate_name,
          //     value: item?.transport_rate_id,
          //   });
          // });
        } else {
          throw packageResult.message;
          // return;
        }
        const siteSeeingResult = await getCitySiteSeeingList();
        if (siteSeeingResult?.data?.status === "SUCCESS") {
          setDataSourceSiteSeeing(siteSeeingResult.data.data);
        } else {
          throw siteSeeingResult.message;
        }
     // }
    } catch (error) {
      errornotify(error);
    }
  };
  /**This useEffect for calling get api*/
  // useEffect(() => {
  //   getAllList();
  // }, [userName]);

  useEffect(() => {
    getAllList();
  }, []);


  useEffect(() => {
    if (destination) {
      let filterSiteSeeing = dataSourceSiteSeeing?.filter((item, index) => {
        return item.destination_id === destination.value;
      });
      setDataSourceSite(filterSiteSeeing[0]?.siteSeeingList);
    }
  }, [destination]);

  /**This is image on change handller*/
  // const onChangeImageHandller = (e) => {
  //   let images = [];
  //   for (let i = 0; i < e.target.files.length; i++) {
  //     images.push(URL.createObjectURL(e.target.files[i]));
  //   }
  //   setSelectedGalleryImages(e.target.files);
  //   setImagePreviews(images);
  //   // setImagePreviewFileName(e.target.files[0].name);
  //   // setImagePreviewFile(URL.createObjectURL(e.target.files[0]));
  //   // setIamgeFile(e.target.files[0]);
  // };
  // const handleDragEnd = (e) => {
  //   setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
  //   if (!e.destination) return;
  //   let tempData = Array.from(cityAndNight);
  //   let [source_data] = tempData.splice(e.source.index, 1);
  //   tempData.splice(e.destination.index, 0, source_data);
  //   setCityAndNight(tempData);
  // };
  /**This is for cancel handller */
  const cancelHandller = (e) => {
    e.preventDefault();
    setmodal_scroll(false);
    setIamgeFile(undefined);
    setImagePreviewFile(undefined);
    setImagePreviewFileName(undefined);
    setIsNullChild({
      ...isNullChild,
      cityAndNightsNullable: false,
      inclusionsNullable: false,
      imagelistNullable: false,
      itineraryNullable: false,
      faqNullable: false,
    });
    setReadOnly(false);
  };

  /**This is option for transport*/
  if (transportList?.length > 0 ) {  //&& defaultDestination?.value
    transportOptions = [];
    transportList
      // .filter((item) => {
      //   return item.is_active && item.destination_id === defaultDestination?.value;
      // })
      .map((item, index) => {
        transportOptions.push({
          label: item.transport_rate_name,
          value: item.transport_rate_id,
        });
      });
  }
  
  /**This function for delete city*/
  // const removeCityHandler = (id) => {
  //   setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
  //   if (cityAndNight?.length > 1) {
  //     const filterCity = cityAndNight.filter((item) => {
  //       return item.id !== id;
  //     });
  //     setCityAndNight(filterCity);
  //   } else {
  //     errornotify("Please add atleast one city.");
  //   }
  // };

  /**This is function for remove itinerary */
  const removeItineraryHandller = (id) => {
    setIsNullChild({ ...isNullChild, itineraryNullable: true });

    const filterItinerary = packageItinerary?.filter((item) => {
      return item.id !== id;
    });
    setPackageItinerary(filterItinerary);
  };

  /**This is function for remove FAQ */
  const removeFaqHandller = (id) => {
    setIsNullChild({ ...isNullChild, faqNullable: true });

    const filterFaq = packageFAQ?.filter((item) => {
      return item.id !== id;
    });
    setPackageFAQ(filterFaq);
  };


  /**This functions for remove inclusions */
  const removeInclustionsHandller = (id) => {
    setIsNullChild({ ...isNullChild, inclusionsNullable: true });
    const filterInclusion = inclusions.filter((item) => {
      return item.id !== id;
    });
    setInclusions(filterInclusion);
  };
  /**This functions for remove exclusions */
  const removeExclusionsHandller = (id) => {
    setIsNullChild({ ...isNullChild, inclusionsNullable: true });
    const filterInclusion = exclusions.filter((item) => {
      return item.id !== id;
    });
    setExclusions(filterInclusion);
  };

   /**This functions for remove inclusions */
   const removePackageDatesHandller = (id) => {

    setIsNullChild({ ...isNullChild, packageDatesListNullable: true });
    const filterPackageDates= packageDatesList.filter((item) => {
      return item.id !== id;
    });
    setPackageDatesList(filterPackageDates);
  };

   /**This functions for remove inclusions */
   const removePackageRatesHandller = (id) => {
    setIsNullChild({ ...isNullChild, packageRatesListNullable: true });
    const filterPackageRates= packageRatesList.filter((item) => {
      return item.id !== id;
    });
    setPackageRatesList(filterPackageRates);
  };

     /**This functions for ItineraryFile */
  const removePackageItineraryFileHandler = () => {    
    setIsRemovedPackageItineraryFile(true);
    
    // setModalValue({
    //   ...modalValue,
    //   packageItineraryFile: ""
    // })

  };

    
  // const onChangeDateTimePicker = (id, e) => {  


  //   if(e != null)
  //     {
  //       const answer_array = e.toString().split(',');
  //       var FromDate =  "";
  //        var ToDate = "";

  //       if(answer_array !=null && answer_array.length == 1)        
  //       {
  //         FromDate = answer_array[0];
  //         ToDate = "";
  //       }
  //       else if(answer_array !=null && answer_array.length > 1)     
  //       {
  //           FromDate = answer_array[0];
  //           ToDate = answer_array[1];         
  //       }

  //       //setDates(FromDate + " ~ " + ToDate);

  //       setIsNullChild({ ...isNullChild, packageDatesListNullable: true });
  //       const updatedArray = packageDatesList.map((item, index) => {
  //         if (id === item.id) {

  //           //alert(JSON.stringify(item));

  //           return { ...item, 
  //                   "packageDateRange": FromDate + " to " + ToDate,
  //                   "packageFromDate": new Date(FromDate),
  //                   "packageToDate": ToDate != "" ? new Date(ToDate)  : ""
  //                 };
          
  //         } else {
  //           return item;
  //         }
  //       });
  //       setPackageDatesList(updatedArray);
  //     }    
  // }

  const onChangeDateTimePicker = (id, fromDate, toDate) => {  

    if(fromDate != null)
      {
        setIsNullChild({ ...isNullChild, packageDatesListNullable: true });
        const updatedArray = packageDatesList.map((item, index) => {
          if (id === item.id) {

            //alert(JSON.stringify(item));

            //This is commented because of a purpose
            return { ...item, 
                    "packageDateRange": fromDate + " to " + toDate,
                     //"packageFromDate": new DateObject(fromDate),
                    // "packageToDate":  toDate != null ?  new DateObject(toDate) : null
                  };
          
          } else {
            return item;
          }
        });
        setPackageDatesList(updatedArray);
      }    
  }
  
  
  const onChangePackageDatesInputs = (e) => {  

    if (
      e.target.name === "packageRemarks" ||
      e.target.name === "packagePrice"
    ) 
    {
        //alert('packageFAQ Title change' + JSON.stringify(packageFAQ));
        setIsNullChild({ ...isNullChild, packageDatesListNullable: true });

        const updatedArray = packageDatesList.map((item, index) => {

           if (e.target.id.toString() === item.id.toString() ) {

            //alert(JSON.stringify(item));

            return { ...item, [e.target.name]: e.target.value };
          } else {
            return item;
          }
        });
        setPackageDatesList(updatedArray);
    } 

  }

  const onChangePackageRatesInputs = (e) => {  

    if (
      e.target.name === "packageRateDescription" ||
      e.target.name === "packageRateBeforeDiscount" || 
      e.target.name === "packageRate" 
    ) 
    {
        //alert('packageFAQ Title change' + JSON.stringify(packageFAQ));

        setIsNullChild({ ...isNullChild, packageRatesListNullable: true });

        const updatedArray = packageRatesList.map((item, index) => {

          if (e.target.id.toString() === item.id.toString() ) {

            //alert(JSON.stringify(item));

            return { ...item, [e.target.name]: e.target.value };
          } else {
            return item;
          }
        });
        setPackageRatesList(updatedArray);
    } 

  }

   const onChangeQuillEditorHandllerNew = (editorId, editorName, editorHTML) => {  

    setIsNullChild({ ...isNullChild, itineraryNullable: true });
      const updatedArray = packageItinerary.map((item, index) => {
        if (parseInt(editorId) === item.id) {
          return { ...item, [editorName]: editorHTML};
        } else {
          return item;
        }
      });
      setPackageItinerary(updatedArray);


  }

 /**onchange editor handller */
 const onChangeQuillEditorHandller = (editorId, editorName, editor) => {    

  
  const data = editor.root.innerHTML;

  if (
    editorName  === "packageDescription"     
    ) {
      setPackageDescription(data);
    } 
  else if (editorName  === "packageGuidelines") {
     setPackageGuidelines(data);     
    } 
  else if (editorName  === "packageDates") {
     setPackageDates(data);    
  } 
  else if (editorName  === "packageRates") {
    setPackageRates(data);    
 } 
  else if (editorName  === "packageWhyus") {
    setPackageWhyus(data);    
    } 
  else if (
    editorName  === "packageItineraryDescription"     
    ) {
      //setIsNullChild({ ...isNullChild, itineraryNullable: true });
      // const updatedArray = packageItinerary.map((item, index) => {
      //   if (parseInt(editorId) === item.id) {
      //     return { ...item, [editorName]: data};
      //   } else {
      //     return item;
      //   }
      // });
      // setPackageItinerary(updatedArray);


      /* New Logic */
      // let tempData = Array.from(packageItinerary);
      // let source_data = tempData.splice(editorId, 0, data);
      // setPackageItinerary(source_data);

      // let [source_data] = tempData.splice(editorId, 1);
      // tempData.splice(e.destination.index, 0, source_data);
      // setCityAndNight(tempData);

  
      let tempData = Array.from(packageItinerary);

      //alert(JSON.stringify(tempData));
      let [source_data] = tempData.splice(editorId - 1, 1);

      source_data.packageItineraryDescription = data;

      tempData.splice(editorId - 1, 0, source_data);

      setPackageItinerary(tempData);

      //tempData.splice(e.destination.index, 0, source_data);
      //setCityAndNight(tempData);

      /* */
    } 
    else if (
        editorName  === "packageFaqDescription"     
        ) {

          //alert('packageFAQ Description change' + JSON.stringify(packageFAQ));

           setIsNullChild({ ...isNullChild, faqNullable: true });
            const updatedArray = packageFAQ.map((item, index) => {
                if (parseInt(editorId) === item.id) 
                {
                  //alert(JSON.stringify(item));
                  return { ...item, "packageFaqDescription": data};
                  //alert(JSON.stringify(item));
                  //return item;
                }
                else {
                  return item;
                }
              });

          setPackageFAQ(updatedArray);    
        }     
        
    else{

    }
  };

    
  const onChangeRatingHandller = (e) => {    
    setModalValue({ ...modalValue, "packageRating" :  e });
  };


  /**input change handller*/
  const onChangeHandller = (e) => {

    if (e.target.name === "is_active") {
      setModalValue({ ...modalValue, [e.target.name]: e.target.checked });
    } else if (
      e.target.name === "createdBy" ||
      e.target.name === "createdDate" ||
      e.target.name === "modifiedBy" ||
      e.target.name === "modifiedDate"
    ) {
      setSwitchCheck({ ...switchCheck, [e.target.name]: !e.target.checked });
    } else if (
      e.target.name === "isbestselling" ||
      e.target.name === "ishoneymoonpackage" ||
      e.target.name === "isfamilypackage" ||
      e.target.name === "isUpcomingPackage"
    ) {
      setSpecialCheckBox({
        ...specialCheckBox,
        [e.target.name]: e.target.checked,
      });
    } else {
      if (e.target.name === "nights") {
        setModalValue({ ...modalValue, [e.target.name]: e.target.value });
        setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });

        /* This was creating array with same cities multiple time
         city 1 - Dubai - 3 nights
         city 2 - Dubai - 4 nights (actually City 2 is Abu dhabi not Dubai)
         To overcome the issue below code is commented
        */
        // const newArray = cityAndNight.map((item, i) => {
        //   if (parseInt(e.target.id) === parseInt(item.id)) {
        //     return {
        //       ...item,
        //       [e.target.name]: e.target.value,
        //       city: cityOptions[0]?.label,
        //       city_id: cityOptions[0]?.value,
        //     };
        //   } else {
        //     return item;
        //   }
        // });
        // setCityAndNight(newArray);

        setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
        const newArray = cityAndNight.map((item, i) => {
          if (parseInt(e.target.id) === parseInt(item.id)) {
            return {
              ...item,
              [e.target.name]: e.target.value,
            };
          } else {
            return item;
          }
        });
        setCityAndNight(newArray);

      } else if (e.target.name === "inclusions") {
        setIsNullChild({ ...isNullChild, inclusionsNullable: true });
        const newArrayInclusions = inclusions?.map((item, i) => {
          if (e.target.id === item.id) {
            return {
              ...item,
              [e.target.name]: e.target.value,
            };
          } else {
            return item;
          }
        });
    
        const newArrayExclusions = exclusions?.map((item, i) => {
          if (e.target.id === item.id) {
            return {
              ...item,
              [e.target.name]: e.target.value,
            };
          } else {
            return item;
          }
        });
        setInclusions(newArrayInclusions);
        setExclusions(newArrayExclusions);
      } else if (
        e.target.name === "packageItineraryDescription" ||
        e.target.name === "packageItineraryTitle" ||
        e.target.name === "day"
      ) {
        setIsNullChild({ ...isNullChild, itineraryNullable: true });
        const updatedArray = packageItinerary.map((item, index) => {
          if (parseInt(e.target.id) === item.id) {
            return { ...item, [e.target.name]: e.target.value };
          } else {
            return item;
          }
        });
        setPackageItinerary(updatedArray);
      } 
      else if (
        e.target.name === "packageFaqDescription" ||
        e.target.name === "packageFaqTitle"
      ) {

        //alert('packageFAQ Title change' + JSON.stringify(packageFAQ));
        setIsNullChild({ ...isNullChild, faqNullable: true });
        const updatedArray = packageFAQ.map((item, index) => {
          //if (parseInt(e.target.id) === item.id) {
            if (e.target.id.toString() === item.id.toString() ) {

            //alert(JSON.stringify(item));

            return { ...item, [e.target.name]: e.target.value };
          } else {
            return item;
          }
        });
        setPackageFAQ(updatedArray);
      }    
      else {
        setModalValue({ ...modalValue, [e.target.name]: e.target.value });
      }
    }


  };

  /**This function for select option handller*/
  const selectOptionHandller = (chooseOption, name, id) => {

    if (name === "city") {
      setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
      const newArray = cityAndNight.map((item, i) => {
        if (item.id === id) {
          return {
            ...item,
            [name]: chooseOption,
          };
        } else {
          return item;
        }
      });
      setCityAndNight(newArray);
    } else {
      if (name === "destinationType") {
        setModalValue((prevValue) => {
          return {
            ...prevValue,
            destination: null,
            city: null,
            transport: null,
          };
        });
        setCityAndNight([
          {
            id: 1,
            city: null,
            nights: undefined,
          },
        ]);
      } else if (name === "destination") {
        setModalValue((prevValue) => {
          return {
            ...prevValue,
            city: null,
            transport: null,
          };
        });
        setCityAndNight([
          {
            id: 1,
            city: null,
            nights: undefined,
          },
        ]);
      }
      setModalValue((prevValue) => {
        return {
          ...prevValue,
          [name]: chooseOption,
        };
      });
    }
  };
  /**This is for export table data to excel*/
  useEffect(() => {
    setExportableData(JSON.parse(JSON.stringify(packageList)));
  }, [
    packageList,
    switchCheck.createdBy,
    switchCheck.createdDate,
    switchCheck.modifiedBy,
    switchCheck.modifiedDate,
  ]);
  useEffect(() => {
    if (searchText === "") {
      setExportableData(JSON.parse(JSON.stringify(packageList)));
    }
  }, [searchText]);
  /**This is for children data*/
  const expandedRowRender = (row) => {

    const transportcolumnDataSource = transportList.filter((item) => {
      return item.transport_rate_id === row.transport_rate_id;
    });
    const siteinclusionsSource = row.inclusionsList.filter((item, index) => {
      return item.is_include;
    });
    const siteExclusionsSource = row.inclusionsList.filter((item, index) => {
      return !item.is_include;
    });
    const columns = [
      {
        title: "#",
        dataIndex: "key",
      },
      {
        title: "City",
        dataIndex: "city_name",
      },
      {
        title: "Nights",
        dataIndex: "nights",
        render: (text, record) => {
          //return <span>{record.nights} Nights</span>;
          return <span>{record?.packageCityHotelList?.[0]?.nights} Nights</span>; 
        },
      },
    ];
    const inclusionsColumns = [
      {
        title: "#",
        dataIndex: "key",
      },
      {
        title: "Inclusions",
        dataIndex: "inclusions",
      },
    ];
    const exclusionListColumns = [
      {
        title: "#",
        dataIndex: "key",
      },
      {
        title: "Exclusions",
        dataIndex: "inclusions",
      },
    ];
    const transportAndPriceColumns = [
      {
        title: "#",
        dataIndex: "key",
      },
      {
        title: "Transport",
        dataIndex: "vehicle_name",
      },
      {
        title: "Price",
        dataIndex: "vehicle_price",
      },
    ];
    return (
      <>
        <Table
          columns={columns}
          dataSource={row.packageCityList}
          pagination={false}     
          style={{paddingLeft: "50px", paddingTop: "10px", width:"50%"}}
        />
        <Table
          columns={inclusionsColumns}
          dataSource={siteinclusionsSource}
          pagination={false}
          style={{paddingLeft: "50px", paddingTop: "10px", width:"50%"}}
        />
        <Table
          columns={exclusionListColumns}
          dataSource={siteExclusionsSource}
          pagination={false}
          style={{paddingLeft: "50px", paddingTop: "10px", width:"50%"}}
        />
        {/* <Table
          columns={transportAndPriceColumns}
          dataSource={transportcolumnDataSource[0].transportRateList}
          pagination={false}
          style={{paddingLeft: "50px", paddingTop: "10px", paddingBottom: "10px", width:"50%"}}
        /> */}
      </>
    );
  };

  // /**This function for handle add city*/
  // const handleAddCity = () => {
  //   setReadOnly(false);
  //   setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
  //   setCityAndNight([
  //     ...cityAndNight,
  //     { id: cityAndNight?.length + 1, city: null, nights: undefined },
  //   ]);
  // };

  /**This function for handle add itinerary  */
  const handleAddItinerary = () => {

    setIsNullChild({ ...isNullChild, itineraryNullable: true });
    setPackageItinerary([
      ...packageItinerary,
      {
        id: packageItinerary?.length + 1,
        day: packageItinerary?.length + 1,
        packageItineraryTitle: undefined,
        packageItineraryDescription: undefined,
      },
    ]);
  };


   /**This function for handle add FAQ  */
   const handleAddFaq= () => {

    setIsNullChild({ ...isNullChild,  faqNullable: true });
    setPackageFAQ([
      ...packageFAQ,
      {
        id: packageFAQ?.length + 1,
        packageFaqId: 0,
        packageFaqTitle: undefined,
        packageFaqDescription: undefined,
      },
    ]);
  };


  /**This function for handle add inclusions*/
  const handleAddInclusions = () => {
    setIsNullChild({ ...isNullChild, inclusionsNullable: true });
    setInclusions([
      ...inclusions,
      {
        id: `${inclusions?.length + 1}-inclusions`,
        inclusions: null,
        is_include: true,
      },
    ]);
  };

  /**This function for handle add exclusions */
  const handleAddExclusions = () => {
    setIsNullChild({ ...isNullChild, inclusionsNullable: true });
    setExclusions([
      ...exclusions,
      {
        id: `${exclusions?.length + 1}-exclusions`,
        inclusions: null,
        is_include: false,
      },
    ]);
  };
  /**primary image radio box handller */
  // const onImagePrimaryHandller = (e, item) => { 
  //   setIsPrimaryImage({
  //     ...isPrimaryImage,
  //     is_primary: e.target.checked,
  //     package_image_id: item.package_image_id,
  //   });
  // };

    /**This function for handle add Package Dates*/
    const handleAddPackageDates = () => {
      setIsNullChild({ ...isNullChild, packageDatesListNullable: true });
      setPackageDatesList([
        ...packageDatesList,
        {
          id: `${packageDatesList?.length + 1}-packageDatesList`,
          packageDateRange: null,
          packageFromDate: null,
          packageToDate: null,
          packageRemarks: null,
          packagePrice: null,      
        },
      ]);
    };

    /**This function for handle add inclusions*/
    const handleAddPackageRates = () => {
      setIsNullChild({ ...isNullChild, packageRatesListNullable: true });
      setPackageRatesList([
        ...packageRatesList,
        {
          id: `${packageRatesList?.length + 1}-packageRatesList`,
          packageRateDescription: null,
          packageRateBeforeDiscount: null,
          packageRate: null
        },
      ]);
    };

  /**Package Save*/
  const modalSaveHandller = async (event) => {

    //let packageName_temp = package_.packageName;

    //Preventing page refresh
    event.preventDefault();
    try {
      setShowInvalid(true);
    
      // if (!transport?.value) {
      //   throw "Please select Transport";
      // }

      let savedCityAndNight = [];
      if (cityAndNight?.length > 0 && inclusions?.length > 0) {
        cityAndNight.map((item, index) => {      
          if (!item.city) {
            throw "City is required.";
          }
          if (!item.nights) {
            throw "Nights is required.";
          }
          /**This is list for save cityandnights */
          savedCityAndNight.push({
            Package_city_id: item.Package_city_id | 0,
            Package_id: package_id,
            // Nights: parseInt(item.nights),
            PackageCityHotelList: [{ Nights: parseInt(item.nights) }],
            // City_id: item?.city_id,
            City_id: item?.city?.value,
            Order_no: index + 1,
            Is_active: is_active,
            Row_created_by: userName,
            Row_created_date: new Date(),
            Row_altered_by: userName,
            Row_altered_date: new Date(),
          });
        });
        /**inclusions list */
        let inclusionsList = [];
        inclusions.map((item, index) => {
          if (!item.inclusions) {
            throw "Inclusions is required";
          }
          inclusionsList.push({
            Inclusions_id: item.Inclusions_id | 0,
            Inclusions: item.inclusions,
            Is_include: item.is_include,
            Order_no: index + 1,
            Is_active: is_active,
            Row_created_by: userName,
            Row_created_date: new Date(),
            Row_altered_by: userName,
            Row_altered_date: new Date(),
          });
        });
        exclusions.map((item, index) => {
          if (!item.inclusions) {
            throw "Exclusions is required";
          }
          inclusionsList.push({
            Inclusions_id: item.Inclusions_id | 0,
            Inclusions: item.inclusions,
            Is_include: item.is_include,
            Order_no: index + 1,
            Is_active: is_active,
            Row_created_by: userName,
            Row_created_date: new Date(),
            Row_altered_by: userName,
            Row_altered_date: new Date(),
          });
        });
        /**itinerary list**/
        let itineraryList = [];
        packageItinerary.map((item, index) => {
          if (!item.day) {
            throw "day is required";
          }
          if (!item.packageItineraryTitle) {
            throw "Package itinerary title is required !";
          }
          if (!item.packageItineraryDescription) {
            throw "Package itinerary Description is required !";
          }
          itineraryList.push({
            Package_itinerary_id: item.Package_itinerary_id | 0,
            Day: parseInt(item.day),
            Itinerary_title: item.packageItineraryTitle,
            Itinerary_description: item.packageItineraryDescription,
            Is_active: is_active,
            Row_created_by: userName,
            Row_created_date: new Date(),
            Row_altered_by: userName,
            Row_altered_date: new Date(),
          });
        });


        /**faq list**/
        let faqList = [];
        packageFAQ.map((item, index) => {          
          if (!item.packageFaqTitle) {
            throw "Package FAQ title is required !";
          }
          if (!item.packageFaqDescription) {
            throw "Package FAQ Description is required !";
          }
          faqList.push({
            // Package_faq_id: item.id | 0,
            Package_faq_id: item.packageFaqId | 0,
            Faq_title: item.packageFaqTitle,
            Faq_description: item.packageFaqDescription,
            Order_no: index + 1,
            Is_active: is_active,
            Row_created_by: userName,
            Row_created_date: new Date(),
            Row_altered_by: userName,
            Row_altered_date: new Date(),
          });
        });

          /**Package Dates list */
          let datesList = [];

          var dateRange_array = null;
          var FromDate = null;
          var ToDate = null;
 

          packageDatesList.map((item, index) => {
            if (!item.packageDateRange) {
              throw "Package Date(s) is required";
            }

            dateRange_array = item.packageDateRange.toString().split(' to ');

            if(dateRange_array !=null && dateRange_array.length == 1)        
              {
                FromDate = dateRange_array[0];
                ToDate = dateRange_array[0];  
              }
              else if(dateRange_array !=null && dateRange_array.length > 1)     
              {  
                  FromDate = dateRange_array[0];
                  ToDate = dateRange_array[1];                   
              }

            datesList.push({
              Package_dates_id: item.id | 0,
              Package_date_range: item.packageDateRange,
              // Package_from_date: new Date(item.packageFromDate),
              // Package_to_date: new Date(item.packageToDate),

              //Original
              // Package_from_date_str: (item.packageFromDate),
              // Package_to_date_str: (item.packageToDate),

              //Trial 1
              Package_from_date_str: FromDate,
              Package_to_date_str: ToDate,




              Package_remarks: (item.packageRemarks),
              Package_price: parseFloat(item.packagePrice),    

              Is_active: is_active,
              Row_created_by: userName,
              Row_created_date: new Date(),
              Row_altered_by: userName,
              Row_altered_date: new Date(),
            });
          });
          

           /**Package Dates list */
           let ratesList = [];
             packageRatesList.map((item, index) => {    

            ratesList.push({
               Package_rates_id: item.id | 0,
               Package_rate_description: item.packageRateDescription,
               Package_rate_before_discount: parseFloat(item.packageRateBeforeDiscount),
               Package_rate: parseFloat(item.packageRate), 
               Package_rate_order_no: parseFloat(index + 1),
               Is_active: is_active,
               Row_created_by: userName,
               Row_created_date: new Date(),
               Row_altered_by: userName,
               Row_altered_date: new Date(),
             });
           });
          
           

        /**Image list */
        let imageList = [];

        debugger;

            
        /**new Gallery images add in list (Selected from Uploaded from Dropzone*/
        if (selectedGalleryImages?.length > 0 || selectedGalleryImages?.length > 0) {
          selectedGalleryImages.map((item, index) => {
            imageList.push({
              Package_image_id: 0,
              Image_name: item.name,
              Is_active: true,
              Is_primary: false,
              Row_created_date: new Date(),
              Row_created_by: userName,
              Row_altered_date: new Date(),
              Row_altered_by: userName,
            });
          }); 
        // } else {
        //   throw "Images are required";
        }


         /**already exists Gallery images add in list */
         if (savedGalleryImages?.length > 0 || savedGalleryImages?.length > 0) {
              savedGalleryImages?.map((item, index) => {
                imageList.push({
                  Package_image_id: item.package_image_id,
                  Image_name: item.image_name,
                  Is_active: true,
                  Is_primary: false,
                  Row_created_date: new Date(),
                  Row_created_by: userName,
                  Row_altered_date: new Date(),
                  Row_altered_by: userName,
                });
              });   
          }

        //Remove previously added  Primart file from The list
        // if((selectedCoverImage ==null || selectedCoverImage?.length == 0)  && 
        //     (getCoverImageFileName ==null || getCoverImageFileName?.length == 0))       
        // {
        //      throw "Cover Image is required";
        //     //let filteredArray = getImageFileNames.filter(item => item.is_primary == 0);      
        //     //setGetImageFileNames(filteredArray); 
        // }
        // else
        // {


          //Now Add new Cover file 
        //debugger;

          // if(selectedGalleryImages !=null)   
          // {
          //   //setSelectedAllFiles(selectedGalleryImages);
          // }

          // if(selectedCoverImage !=null)   
          // {
          //        setSelectedAllFiles.push(selectedCoverImage);
          //     //  selectedGalleryImages.push({
          //     //   name: selectedCoverImage.name,
          //     //   preview: selectedCoverImage.preview,
          //     //   formattedSize: selectedCoverImage.formattedSize,
          //     // });           
          // }

          // if(selectedProfileImage!=null)   
          //   {
          //     setSelectedAllFiles.push(selectedProfileImage);
          //     // selectedGalleryImages.push({
          //     //   name: selectedProfileImage.name,
          //     //   preview: selectedProfileImage.preview,
          //     //   formattedSize: selectedProfileImage.formattedSize,
          //     // });    
          //   }

            var finalImageList  =  readOnly && !imagelistNullable && savedGalleryImages ?.length > 0 && selectedGalleryImages?.length > 0
            ? null
            : imageList;

            //Now Add new Primary file 
            // if(selectedPrimaryFile !=null && selectedPrimaryFile?.length > 0)   
            // {
            //     selectedPrimaryFile.map((item, index) => {
            //       imageList.push({
            //         Package_image_id: 0,
            //         Image_name: item.name,
            //         Is_active: true,
            //         Is_primary: true,
            //         Row_created_date: new Date(),
            //         Row_created_by: userName,
            //         Row_altered_date: new Date(),
            //         Row_altered_by: userName,
            //       });
            //     });
            //  }
            //  else if(getPrimaryImageFileName !=null && getPrimaryImageFileName?.length > 0)   
            //   {
            //        getPrimaryImageFileName.map((item, index) => {
            //         imageList.push({
            //           Package_image_id: item.package_image_id,
            //           Image_name: item.image_name,
            //           Is_active: true,
            //           Is_primary: true,
            //           Row_created_date: new Date(),
            //           Row_created_by: userName,
            //           Row_altered_date: new Date(),
            //           Row_altered_by: userName,
            //         });
            //       });
            //    }

       // }
 
        let tempPackageTitle = "";
         if (packageTitle == "")
         {
          tempPackageTitle = packageName?.toLowerCase();
          tempPackageTitle = tempPackageTitle.replaceAll(' ', '-');
         }

          //Userd to check if data is present in the text editor or not
          var regex = /(<([^>]+)>)/ig;    

      

        const packageList = {
          Operation: !readOnly ? "ADD" : "EDIT" ,
          Package_id: package_id,
          Transport_rate_id: transport?.value,
          // Destination_id: destination?.destination_id,
          Destination_id: destination?.value,
          Package_name: packageName,   
          Package_title: packageTitle || tempPackageTitle ,     
          Package_price: parseFloat(packagePrice),
          Package_price_before_discount: parseFloat(packagePriceBeforeDiscount),
          Package_rating: parseFloat(packageRating),
          Package_rating_average: parseInt(packageRatingAverage),
          
          Package_description: !!packageDescription.replace(regex, "") ? packageDescription : null ,
          Package_commision: parseInt(packageCommision), 

          Package_guidelines: !!packageGuidelines.replace(regex, "") ? packageGuidelines : null,
          Package_dates : !!packageDates.replace(regex, "") ? packageDates : null,
          Package_rates : !!packageRates.replace(regex, "") ? packageRates : null,
          Package_whyus:   !!packageWhyus.replace(regex, "") ? packageWhyus : null ,
          
          PackageCityList:
            readOnly && !cityAndNightsNullable ? null : savedCityAndNight,
          InclusionsList:
            readOnly && !inclusionsNullable ? null : inclusionsList,
          ImageList: finalImageList,
          Package_itinerary_list:
            readOnly && !itineraryNullable ? null : itineraryList,
          PackageFaqList:
            readOnly && !faqNullable ? null : faqList,
          PackageDatesList:
            readOnly && !packageDatesList ? null : datesList,
          PackageRatesList:
            readOnly && !packageRatesList ? null : ratesList,

          Is_best_selling: specialCheckBox.isbestselling,
          Is_honeymoon_package: specialCheckBox.ishoneymoonpackage,
          Is_family_package: specialCheckBox.isfamilypackage,
          Is_upcoming_package: specialCheckBox.isUpcomingPackage,
          Is_active: is_active,
          Package_order_no: parseInt(packageOrderNo),
          Package_itinerary_file: selectedPackageItineraryFile?.name,
          Cover_image_name: selectedCoverImage?.name || savedCoverImage?.image_name,
          Profile_image_name: selectedProfileImage?.name || savedProfileImage?.image_name,

          Row_created_by: userName,
          Row_created_date: new Date(),
          Row_altered_by: userName,
          Row_altered_date: new Date(),
        };

        const response = await SavePackageList(packageList);
  
        if (response?.status === "SUCCESS") {          
          try {
            let new_package_id =  response?.data?.[1];

            if (deleteImageList.length > 0) {
              /**This is for delete images */
              const response = await DeletePackageImageList(deleteImageList);
              if (response.status === "ERROR") {
                throw response.message;
                // return;
              }
            }

            // if (selectedPrimaryFile.length > 0) {
            //   /**This is for save images*/
            //   const formData = new FormData();
            //   formData.append("Package_id", new_package_id);
            //   selectedPrimaryFile.map(async (item, index) => {
            //     formData.append("file", item);
            //     let response = await savePackageImageList(formData);
            //     if (response.status === "ERROR") {
            //       throw response.message;
            //       // return;
            //     }
            //   });
            // }

            if (selectedGalleryImages.length > 0) {
              /**This is for save images*/
              const formData = new FormData();
              formData.append("Package_id", new_package_id);
              selectedGalleryImages.map(async (item, index) => {
                formData.append("file", item);
                let response = await savePackageImageList(formData);
                if (response.status === "ERROR") {
                  throw response.message;
                  // return;
                }
              });
            }

            if(selectedCoverImage !=null)   
            {
              const formData = new FormData();
              formData.append("Package_id", new_package_id);        
              formData.append("file", selectedCoverImage);
              let response = await savePackageImageList(formData);
              if (response.status === "ERROR") {
                throw response.message;
              }       
            }

            if(selectedProfileImage !=null)   
              {
                const formData = new FormData();
                formData.append("Package_id", new_package_id);        
                formData.append("file", selectedProfileImage);
                let response = await savePackageImageList(formData);
                if (response.status === "ERROR") {
                  throw response.message;
                }       
              }

            //Save selected Package Itinary File
            if (selectedPackageItineraryFile != null) {
              /**This is for save images*/
              const formData = new FormData();
              formData.append("Package_id", new_package_id);             
              formData.append("file", selectedPackageItineraryFile);
              let response = await savePackageItineraryFile(formData);
              if (response.status === "ERROR") {
                throw response.message;
                // return;
              }             
            }
    

            if(isRemovedPackageItineraryFile)
            {
               /**This is for save images*/
               const formData = new FormData();
               formData.append("Package_id", new_package_id);             
               formData.append("Package_itinerary_file", modalValue.packageItineraryFile);
               let response = await deletePackageItineraryFile(formData);
               if (response.status === "ERROR") {
                 throw response.message;
                 // return;
               }       
            }

          } catch (error) {
            errornotify(error);
            // return;
          }
          successNotify("Package '" + packageName  + "' saved successfully!");
          await getAllList();
          tog_scroll();
          setShowInvalid(false);
          setCityAndNight({
            id: 1,
            city: null,
            nights: undefined,
          });
          setInclusions([
            {
              id: `${1}-inclusions`,
              inclusions: null,
              is_include: true,
            },
          ]);
          setExclusions([
            {
              id: `${1}-exclusions`,
              inclusions: null,
              is_include: false,
            },
          ]);
          setModalValue({

            // destination: null,
            // destinationType: null,

            packageCommision: 0,
            packageName: "",
            packageTitle: "",
            transport: null,

            packageGuidelines: "",
            packageDates : "",
            packageRates : "",
            packageWhyus: "",
           
            is_active: is_active,
        
          });
          setIsNullChild({
            ...isNullChild,
            cityAndNightsNullable: false,
            inclusionsNullable: false,
            imagelistNullable: false,
            itineraryNullable: false,
            faqNullable: false
          });
          setReadOnly(false);
        } else {
          throw response?.message;
        }
      }
    } catch (error) {
      errornotify(error);
    }
  };


    /**Package Duplicate*/
    const duplicatePackageHandller = async () => {         
  
      try {
                      
        let params = new URLSearchParams();
        params.append("Package_id", parseInt(duplicatePackageId));
        params.append("User_id", userName);
        
          // const packageList = {
          //   Package_id: package_id,
          //   User_id: transport?.value
          // }

          try {
            const response = await DuplicatePackage(params);
            if (response?.data?.status === "SUCCESS") {
              successNotify("Package '" + duplicatePackageName  + "' duplicated successfully!");
            }      
            else{
              errornotify(response.data.message);
            }       
            toggleModalDuplicatePackage();
            await getAllList();
                         

          } catch (error) {
            errornotify(error);
            // return;
          }
         
        
      } catch (error) {
        errornotify(error);
      }
    };
  

  /**Package Duplicate*/
    const duplicatePackageHandller2 = async (package_id, package_name) => {         
  
      try {
          
        let params = new URLSearchParams();
        params.append("Package_id", parseInt(package_id));
        params.append("User_id", userName);
                   
          // const packageList = {
          //   Package_id: package_id,
          //   User_id: transport?.value
          // }

          try {
            const response = await DuplicatePackage(params);
            if (response?.data?.status === "SUCCESS") {
              successNotify("Package '" + package_name  + "' duplicated successfully!");
            }      
            else{
              errornotify(response.data.message);
            }       
           
            await getAllList();
                         

          } catch (error) {
            errornotify(error);
            // return;
          }
         
        
      } catch (error) {
        errornotify(error);
      }
    };
  

  /**For column search */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTextantd(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTextantd("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchTextantd(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={{ backgroundColor: "#233163" }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#233163" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextantd]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  /**Table and column*/
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (text, record) => {
        return (

          <div className="text-center">          
            <a href="#" title="Edit Package" onClick={() => editHandller(record)}  >
                <i className="ri-pencil-fill fs-18 pr-1"> </i>           
            </a>
            {"   "}     
            <a href="#" title="Duplicate Package" onClick={() => toggleModalDuplicatePackage(record.package_id, record.package_name)}  >
                <i className="ri-file-copy-2-fill  fs-18"> </i>           
            </a>        
          </div>
        );
      },
    },

    
    // {
    //   title: "Destination Type",
    //   dataIndex: "destination_type_id",
    //   filters: destinationTypesFilter,
    //   // filterMode: "tree",
    //   onFilter: (value, record) => record.destination_type_id === value,
    //   render: (text, record) => {
    //     return record.destination_type_id === 1 ? "Domestic" : "International";
    //   },
    //   sorter: {
    //     compare: (a, b) => a.destination_type_id - b.destination_type_id,
    //     multiple: 13,
    //   },
    //   defaultSortOrder: "ascend",
    //   // sorter: (a, b) => a.destination_type_id - b.destination_type_id,
    // },
    {
      title: "Destination",
      dataIndex: "destination_name",
      width: 140,
      // ...getColumnSearchProps("destination_name"),
      filters: filterColumnWiseDestination,
      // filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.destination_id === value,
      sorter: {
        compare: (a, b) => a.destination_name.localeCompare(b.destination_name),
        multiple: 12,
      },
      defaultSortOrder: "ascend",
      //hidden: !showDestination,
      // sorter: (a, b) => a.destination_name.localeCompare(b.destination_name),
    },
    {
      title: "Package Order",
      dataIndex: "package_order_no",
      width: 100,
      sorter: {
        compare: (a, b) => a.package_order_no - b.package_order_no,
        multiple: 11,
      },
      defaultSortOrder: "ascend",
      ...getColumnSearchProps("package_order_no"),
    },
    {
      title: "Package",
      dataIndex: "package_name",
      width: 200,
      sorter: {
        compare: (a, b) => a.package_name.localeCompare(b.package_name),
        multiple: 11,
      },
      //defaultSortOrder: "ascend",
      // sorter: (a, b) => a.package_name.localeCompare(b.package_name),
      ...getColumnSearchProps("package_name"),
    },
    // {
    //   title: "Package Description",
    //   width: 200,
    //   dataIndex: "package_description",
    //   render: (text, record) => {
    //     return (
    //       <>
    //         <UncontrolledTooltip
    //           placement="top"
    //           target={`tooltipId${record.package_id}`}
    //         >
    //           {record.package_description}
    //         </UncontrolledTooltip>
    //         <p
    //           style={{
    //             whiteSpace: "nowrap",
    //             overflow: "hidden",
    //             textOverflow: "ellipsis",
    //             width: "150px",
    //           }}
    //           id={`tooltipId${record.package_id}`}
    //         >
    //           {record.package_description}
    //         </p>
    //       </>
    //     );
    //   },
    //   sorter: {
    //     compare: (a, b) => a.package_description.localeCompare(b.package_description),   
    //   },
    // },
    // {
    //   title: "Transport",
    //   width: 250,
    //   dataIndex: "transport_rate_name",
    //   filters: filterColumnWiseTransport,
    //   sorter: {
    //     compare: (a, b) =>
    //       a.transport_rate_name.localeCompare(b.transport_rate_name),
    //     multiple: 10,
    //   },
    //   defaultSortOrder: "ascend",
    //   // sorter: (a, b) =>
    //   //   a.transport_rate_name.localeCompare(b.transport_rate_name),
    //   filterSearch: true,
    //   onFilter: (value, record) => record.transport_rate_id === value,
    // },

    {
      title: "Package Price",
      width: 150,
      dataIndex: "package_price",
      sorter: {
        compare: (a, b) => a.package_price - b.package_price,
        //multiple: 9,
      },
      align: "right",
      //defaultSortOrder: "ascend",    
      render: (text, record) => {
        return (
          <>
            <CurrencyShow
              currency={record?.package_price}
              currencySymbol={currencySymbol}
            />
          </>
        );
      },
    },
    {
      title: "Before Discount",
      width: 150,
      dataIndex: "package_price_before_discount",
      sorter: {
        compare: (a, b) => a.package_price_before_discount - b.package_price_before_discount,
        //multiple: 9,
      },
      //defaultSortOrder: "ascend",    
      align: "right",
      render: (text, record) => {
        return (
          <>
            <CurrencyShow
              currency={record?.package_price_before_discount}
              currencySymbol={currencySymbol}
            />
          </>
        );
      },
    },  
    {
      title: "Package Nights",
      width: 100,
      dataIndex: "package_duration",
      sorter: {
        compare: (a, b) => a.package_duration - b.package_duration,
        //multiple: 9,
      },
      //defaultSortOrder: "ascend",
      align: "center",
      // sorter: (a, b) => a.package_commision - b.package_commision,
      render: (text, record) => {

        //var sumOfNights = (record?.packageCityList[0].packageCityHotelList?.reduce((a,v) =>  a = a + v.nights, 0 ));
        //return <div>{sumOfNights}</div>;
        return <div>{record?.package_duration}</div>;
        
        // return <div>{record?.packageCityList[0].packageCityHotelList[0]?.nights}</div>;
      },
    },

    {
      title: "Is Active",
      dataIndex: "is_active",
      width: 130,
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_active === value,
      sorter: {
        compare: (a, b) => (a, b) => a.is_active - b.is_active,
        multiple: 8,
      },
      // sorter: (a, b) => a.is_active - b.is_active,
      render: (text, record) => {
        return (
          //alert(record.package_name + '-' + record.is_active)
          <div className="form-check form-switch form-switch-success">

            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_active == e.target.checked)}
              defaultChecked={record.is_active || 0}
              checked = {record.is_active || 0}
              disabled
            />
          </div>
        );
      },
    },
    {
      title: "Is Best Selling",
      dataIndex: "is_best_selling",
      width: 170,
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_best_selling === value,
      sorter: {
        compare: (a, b) => a.is_best_selling - b.is_best_selling,
        multiple: 7,
      },
      // sorter: (a, b) => a.is_best_selling - b.is_best_selling,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_best_selling = e.target.checked)}
              defaultChecked={record.is_best_selling}
              checked={record.is_best_selling}
              disabled
            />
          </div>
        );
      },
    },
  
    {
      // title: "Is Honeymoon Package",
      title: "Is Customize Tour",
      width: 170,
      dataIndex: "is_honeymoon_package",
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_honeymoon_package === value,
      sorter: {
        compare: (a, b) => a.is_honeymoon_package - b.is_honeymoon_package,
        multiple: 5,
      },
      // sorter: (a, b) => a.is_honeymoon_package - b.is_honeymoon_package,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_honeymoon_package = e.target.checked)}
              defaultChecked={record.is_honeymoon_package}
              checked={record.is_honeymoon_package}
              disabled
            />
          </div>
        );
      },
    },
    {
      // title: "Is Family Package",
      title: "Is Group Tour",
      width: 170,
      dataIndex: "is_family_package",
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_family_package === value,
      sorter: {
        compare: (a, b) => a.is_family_package - b.is_family_package,
        multiple: 6,
      },
      // sorter: (a, b) => a.is_family_package - b.is_family_package,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_family_package = e.target.checked)}
              defaultChecked={record.is_family_package}
              checked={record.is_family_package}
              disabled
            />
          </div>
        );
      },
    },

    {  
      title: "Is Upcoming Tour",
      width: 150,
      dataIndex: "is_upcoming_package",
      filters: [
        {
          text: "True",
          value: true,
        },
        {
          text: "False",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_upcoming_package === value,
      sorter: {
        compare: (a, b) => a.is_upcoming_package - b.is_upcoming_package,
        multiple: 6,
      },     
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_upcoming_package = e.target.checked)}
              defaultChecked={record.is_upcoming_package}
              checked={record.is_upcoming_package}
              disabled
            />
          </div>
        );
      },
    },

    {
      title: "Created By",
      width: 250,
      dataIndex: "row_created_by",
      sorter: {
        compare: (a, b) => a.row_created_by.localeCompare(b.row_created_by),
        multiple: 4,
      },
      hidden: switchCheck.createdBy,
    },
    {
      title: "Created On",
      width: 160,
      dataIndex: "row_created_date",
      sorter: {
        compare: (a, b) => a.row_created_date.localeCompare(b.row_created_date),
        multiple: 3,
      },
      hidden: switchCheck.createdDate,
      render: (text, record) => {
        const date = new Date(record.row_created_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
    {
      title: "Modified By",
      width: 250,
      dataIndex: "row_altered_by",
      sorter: {
        compare: (a, b) => a.row_altered_by.localeCompare(b.row_altered_by),
        multiple: 2,
      },
      hidden: switchCheck.modifiedBy,
    },
    {
      title: "Modified On",
      width: 160,
      dataIndex: "row_altered_date",
      sorter: {
        compare: (a, b) => a.row_altered_date.localeCompare(b.row_altered_date),
        multiple: 1,
      },
      hidden: switchCheck.modifiedDate,
      render: (text, record) => {
        const date = new Date(record.row_altered_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  /**This function for modal open and close*/
  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
  }
  /**This is for add button handller*/
  const AddHandler = () => {
    setSelectedGalleryImages([]);
    //setGetImageFileNames([]);
    setSavedGalleryImages([]);

    setDeleteImageList([]);
    setactiveArrowTab(1);
    setPassedarrowSteps([1]);
    set_package_id(0);
    // setPackageName(undefined);
    setPackageDescription("");

    setPackageGuidelines("");
    setPackageDates("");
    setPackageWhyus("");

    setSpecialCheckBox({
      isbestselling: false,
      ishoneymoonpackage: false,
      isfamilypackage: false,
      isUpcomingPackage: false
    });
    setModalValue({
      
      // destinationType:  defaultDestinationType,     
      // destination: defaultDestination,
      ...modalValue,
      transport: null,
      destinationType:JSON.parse(localStorage.getItem("global_search_destination_type_option")),
      destination: JSON.parse(localStorage.getItem("global_search_destination_option")),
      packageName: "",
      packageRating:3,
      packageCommision: null,
      is_active: true,     
    });
    setCityAndNight([
      {
        id: 1,
        city: null,
        nights: undefined,
      },
    ]);
    setPackageItinerary([
      {
        id: 1,
        day: 1,
        packageItineraryTitle: undefined,
        packageItineraryDescription: undefined,
      },
    ]);

    setPackageFAQ([
      {
        id: 1,
        packageFaqId: 0,
        packageFaqTitle: "",
        packageFaqDescription: "",
      },
    ]);

    setInclusions([
      {
        id: `${1}-inclusions`,
        inclusions: null,
        is_include: true,
      },
    ]);
    setExclusions([
      {
        id: `${1}-exclusions`,
        inclusions: null,
        is_include: false,
      },
    ]);

     setPackageDatesList([
        {
          id: `${1}-packageDatesList`,
          packageDateRange: null,
          packageFromDateStr: null,
          packageToDateStr: null,
  
          packageRemarks: null,
          packagePrice: null
        },
      ]);

       

    setReadOnly(false);

    // if (cityList?.length > 0 ) {  //&& destination?.value
    //   cityOptions = [];
    //   cityList
    //     // .filter((item) => {
    //     //   return item.destination_id === destination?.value && item.is_active;
    //     // })
    //     .map((item, index) => {
    //       cityOptions.push({
    //         label: item.city_name,
    //         value: item.city_id,
    //       });
    //     });
    // }

    
    tog_scroll();
  };
  /**set exported Data*/
  let exportArray = [];
  /** Export data*/
  useEffect(() => {
    setExportedData([]);
    if (exportTableData.length > 0) {
      exportTableData?.forEach((elementParent) => {
        exportArray.push({
          Destination_type:
            elementParent.destination_type_id === 1
              ? "Domestic"
              : "International",
          Destination_name: elementParent.destination_name,
          Transport_rate_name: elementParent.transport_rate_name,
          Package_name: elementParent.package_name,
          Package_commision: elementParent.package_commision,
          Is_active: elementParent.is_active,
          Is_best_selling: elementParent.is_best_selling,
          Is_family_package: elementParent.is_family_package,
          Is_honeymoon_package: elementParent.is_honeymoon_package,
          ...(!switchCheck.createdBy && {
            Row_createdBy: elementParent.row_created_by,
          }),
          ...(!switchCheck.createdDate && {
            Row_createdDate: elementParent.row_created_date,
          }),
          ...(!switchCheck.modifiedBy && {
            Row_modifiedBy: elementParent.row_altered_by,
          }),
          ...(!switchCheck.modifiedDate && {
            Row_modifiedDate: elementParent.row_altered_date,
          }),
        });
        elementParent?.packageCityList?.forEach((element) =>
          exportArray.push({
            Destination_type:
              elementParent.destination_type_id === 1
                ? "Domestic"
                : "International",
            Destination_name: elementParent.destination_name,
            Transport_rate_name: elementParent.transport_rate_name,
            Package_name: elementParent.package_name,
            Package_commision: elementParent.package_commision,
            Is_active: elementParent.is_active,
            ...(!switchCheck.createdBy && {
              Row_createdBy: elementParent.row_created_by,
            }),
            ...(!switchCheck.createdDate && {
              Row_createdDate: elementParent.row_created_date,
            }),
            ...(!switchCheck.modifiedBy && {
              Row_modifiedBy: elementParent.row_altered_by,
            }),
            ...(!switchCheck.modifiedDate && {
              Row_modifiedDate: elementParent.row_altered_date,
            }),
            City: element.city_name,
            Nights: element.nights,
          })
        );
        elementParent?.inclusionsList?.forEach((elementInclusions) => {
          exportArray.push({
            Destination_type:
              elementParent.destination_type_id === 1
                ? "Domestic"
                : "International",
            Destination_name: elementParent.destination_name,
            Transport_rate_name: elementParent.transport_rate_name,
            Package_name: elementParent.package_name,
            Package_commision: elementParent.package_commision,
            Package_description: elementParent.package_description,
            Is_active: elementParent.is_active,
            ...(!switchCheck.createdBy && {
              Row_createdBy: elementParent.row_created_by,
            }),
            ...(!switchCheck.createdDate && {
              Row_createdDate: elementParent.row_created_date,
            }),
            ...(!switchCheck.modifiedBy && {
              Row_modifiedBy: elementParent.row_altered_by,
            }),
            ...(!switchCheck.modifiedDate && {
              Row_modifiedDate: elementParent.row_altered_date,
            }),
            Inclusions: elementInclusions.inclusions,
          });
        });
        transportList
          ?.filter((itemTransportList) => {
            return (
              itemTransportList.transport_rate_id ===
              elementParent.transport_rate_id
            );
          })[0]
          ?.transportRateList.forEach((vehicleItem) => {
            exportArray.push({
              Destination_type:
                elementParent.destination_type_id === 1
                  ? "Domestic"
                  : "International",
              Destination_name: elementParent.destination_name,
              Transport_rate_name: elementParent.transport_rate_name,
              Package_name: elementParent.package_name,
              Package_commision: elementParent.package_commision,
              Is_active: elementParent.is_active,
              ...(!switchCheck.createdBy && {
                Row_createdBy: elementParent.row_created_by,
              }),
              ...(!switchCheck.createdDate && {
                Row_createdDate: elementParent.row_created_date,
              }),
              ...(!switchCheck.modifiedBy && {
                Row_modifiedBy: elementParent.row_altered_by,
              }),
              ...(!switchCheck.modifiedDate && {
                Row_modifiedDate: elementParent.row_altered_date,
              }),
              Transport: vehicleItem.vehicle_name,
              Price: vehicleItem.vehicle_price,
            });
          });
      });
      setExportedData(exportArray);
    }
  }, [exportTableData, filterData]);
  
  const navigateImportPackage = () => {
    navigate('../importPackage', {replace: true});
  };

  
  /**This function for handle add city*/
  const handleAddCity = () => {
    setReadOnly(false);
    setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
    setCityAndNight([
      ...cityAndNight,
      { id: cityAndNight?.length + 1, city: null, nights: undefined },
    ]);
  };

    /**This function for delete city*/
    const removeCityHandler = (id) => {
      setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
      if (cityAndNight?.length > 1) {
        const filterCity = cityAndNight.filter((item) => {
          return item.id !== id;
        });
        setCityAndNight(filterCity);
      } else {
        errornotify("Please add atleast one city.");
      }
    };

  const handleDragEnd = (e) => {
    setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
    if (!e.destination) return;
    let tempData = Array.from(cityAndNight);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setCityAndNight(tempData);
  };

  const handleDragEndInclusions = (e) => {

    setIsNullChild({ ...isNullChild, inclusionsNullable: true });
    if (!e.destination) return;
    let tempData = Array.from(inclusions);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setInclusions(tempData);
  };

  const handleDragEndExclusions = (e) => {

    setIsNullChild({ ...isNullChild, inclusionsNullable: true });
    if (!e.destination) return;
    let tempData = Array.from(exclusions);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setExclusions(tempData);
  };

  const handleDragEndFaq = (e) => {

    setIsNullChild({ ...isNullChild, faqNullable: true });
    if (!e.destination) return;
    let tempData = Array.from(packageFAQ);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setPackageFAQ(tempData);
  };

  
  const handleDragEndpackageDates = (e) => {

    setIsNullChild({ ...isNullChild, packageDatesListNullable: true });
    if (!e.destination) return;
    let tempData = Array.from(packageDatesList);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setPackageDatesList(tempData);
  };

  const handleDragEndpackageRates = (e) => {

    setIsNullChild({ ...isNullChild, packageRatesListNullable: true });
    if (!e.destination) return;
    let tempData = Array.from(packageRatesList);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setPackageRatesList(tempData);
  };



  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          {/* <BreadCrumb title="Package" pageTitle="Package" /> */}
          <BreadCrumb
            title="Packages"
            isSubTitle={false}
          />
          
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>                       
                          <ButttonTravelNinjaz
                            backGroundColor="success"
                            className="add-btn me-1 my-1"
                            id="create-btn"
                            onCLickHancller={AddHandler}
                            buttonIcon={
                              <i className="ri-add-line align-bottom me-1"></i>
                            }
                            buttonText="Add"
                          />
                          {/**Export data */}
                          <Export
                            downloadExcel={downloadExcel}
                            exportedData={exportedData}
                            name="PackageList"
                          />

                        <ButttonTravelNinjaz
                            backGroundColor="primary"
                            className="btn btn-secondary me-1 mx-1 my-1"
                            id="cancel-btn"
                            onCLickHancller={() =>
                              navigateImportPackage()
                            }                            
                            buttonText="Import"                            
                          />



                          {/**Hide and show column*/}
                          <ColumnVisibleAndHide
                            changeHandller={onChangeHandller}
                            switchCheck={switchCheck}
                          />
                        </div>
                      </Col>
                      {/**This is for search input field */}
                      <Mastersearch inputHandller={searchInputHandller} />
                    </Row>
                  </div>
                  <div>
                    {searchText?.length > 0 ? (
                      <Table
                        dataSource={filterData}
                        columns={columns}
                        scroll={{
                          y: 320,
                        }}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: [10, 20, 50, 100],
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items  `,
                        }}
                        expandedRowRender={expandedRowRender}
                        bordered
                        locale={{
                          triggerDesc: null,
                          triggerAsc: null,
                          cancelSort: null,
                        }}
                      />
                    ) : (
                      <Table
                        dataSource={packageList}
                        expandedRowRender={expandedRowRender}
                        columns={columns}
                        scroll={{
                          y: 320,
                        }}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: [10, 20, 50, 100],
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items  `,
                        }}
                        bordered
                        locale={{
                          triggerDesc: null,
                          triggerAsc: null,
                          cancelSort: null,
                        }}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/**This modal for form steps*/}
      <Modal
        isOpen={modal_scroll}
        toggle={() => {
          tog_scroll();
        }}
        centered
       // size="xl"
        modalClassName="zoomIn"
        backdrop = {"static"}
        fullscreen
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_scroll();
          }}
        >

        {!readOnly ? "Add" : "Edit"} Package
        {!packageName ? "" : `: ${packageName}`}
          {/* {!readOnly ? "Add Package: " : "Edit Package: " + `${packageName}` } */}
        </ModalHeader>
        <Col xl={12}>
          <Card style={{ minHeight: "75vh" }}>
            <CardBody className="form-steps">
              <Form>
                {/* <div className="step-arrow-nav mb-4"> */}
                <div className="mb-4">

                <Row>

                <Col lg={2}>
                <Nav
                    // className="nav-pills custom-nav nav-justified"
                    className="nav nav-pills flex-column nav-pills-tab custom-verti-nav-pills text-center"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 1,
                          //done: activeArrowTab <= 1,
                          done: 1 ,
                        })}
                        onClick={() => {
                          toggleArrowTab(1);
                        }}
                      >
                        Package
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 2,
                          // done: activeArrowTab >= 1 && activeArrowTab <= 2 ,
                          done: 2 ,
                        })}
                        onClick={() => {
                          toggleArrowTab(2);
                        }}
                      >
                        City
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 3,
                          //done: activeArrowTab >= 1 && activeArrowTab <= 3 ,
                          done: 3,
                        })}
                        onClick={() => {
                          toggleArrowTab(3);
                        }}
                      >
                        Site Seeings
                      </NavLink>
                    </NavItem> */}
                    {/* <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 4,
                          //done: activeArrowTab >= 1 && activeArrowTab <= 4 ,
                          done: 4,
                        })}
                        onClick={() => {
                          toggleArrowTab(4);
                        }}
                      >
                        Transport
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 5,
                          //done: activeArrowTab >= 1 && activeArrowTab <= 5 ,
                          done: 5,
                        })}
                        onClick={() => {
                          toggleArrowTab(5);
                        }}
                      >
                        Inclusions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 6,
                          // done: activeArrowTab >= 1 && activeArrowTab <= 6,
                          done: 6,
                        })}
                        onClick={() => {
                          toggleArrowTab(6);
                        }}
                      >
                        Exclusions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 7,
                          // done: activeArrowTab >= 1 && activeArrowTab <= 7,
                          done:7,
                        })}
                        onClick={() => {
                          toggleArrowTab(7);
                        }}
                      >
                        Images
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 8,
                          //done: activeArrowTab >= 1 && activeArrowTab <= 8,
                           done: 8,
                        })}
                        onClick={() => {
                          toggleArrowTab(8);
                        }}
                      >
                        Itinerary
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-dates-info-tab"
                        className={classnames({
                          active: activeArrowTab === 13,
                          done: 13,
                        })}
                        onClick={() => {
                          toggleArrowTab(13);
                        }}
                      >
                        Rates
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-dates-info-tab"
                        className={classnames({
                          active: activeArrowTab === 11,
                          done: 11,
                          // done: activeArrowTab >= 1 && activeArrowTab <= 9,
                        })}
                        onClick={() => {
                          toggleArrowTab(11);
                        }}
                      >
                        Dates
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 9,
                          done: 9,
                          // done: activeArrowTab >= 1 && activeArrowTab <= 9,
                        })}
                        onClick={() => {
                          toggleArrowTab(9);
                        }}
                      >
                        Guidelines
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 10,
                          done: 10,
                          // done: activeArrowTab >= 1 && activeArrowTab <= 9,
                        })}
                        onClick={() => {
                          toggleArrowTab(10);
                        }}
                      >
                        Why Us
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 12,
                          done: 12,
                        })}
                        onClick={() => {
                          toggleArrowTab(12);
                        }}
                      >
                       FAQ
                      </NavLink>
                    </NavItem>
                  </Nav>

                </Col>

                <Col lg={10}>
                <TabContent activeTab={activeArrowTab}>
                  <TabPane id="steparrow-destination-info" tabId={1}>

                    <DestinationAndPackage
                      destinationType={destinationType}
                      destination={destination}
                      packageTitle = {packageTitle}
                      package_id = {package_id}
                      packageName = {packageName}
                      packageDescription = {packageDescription}
                      packagePrice = {packagePrice}
                      packagePriceBeforeDiscount = {packagePriceBeforeDiscount}
                      packageRating = {packageRating}
                      packageRatingAverage = {packageRatingAverage}
                      packageCommision = {packageCommision}                    
                      animatedComponents={animatedComponents}
                      cancelHandller={cancelHandller}                     
                      onChangeHandller={onChangeHandller}    
                      onChangeQuillEditorHandller = {onChangeQuillEditorHandller}                    
                      onChangeRatingHandller = {onChangeRatingHandller}
                      readOnly={readOnly}
                      selectOptionHandller={selectOptionHandller}
                      showInvalid={showInvalid}
                      specialCheckBox={specialCheckBox}  
                      is_active = {is_active}
                      packageOrderNo = {packageOrderNo}
                      //modalSaveDestinationAndPackageHandller = {modalSaveDestinationAndPackageHandller}
                      modalSaveHandller={modalSaveHandller}

                      onPackageItineraryFileChange = {onPackageItineraryFileChange}
                      selectedPackageItineraryFile = {selectedPackageItineraryFile}
                      packageItineraryFile = {packageItineraryFile}
                      removePackageItineraryFileHandler = {removePackageItineraryFileHandler}
                      //setSelectedPackageItineraryFile = {setSelectedPackageItineraryFile}
                  />
                  </TabPane>

                  <TabPane id="steparrow-cityandnight-info" tabId={2}>
                    <PackageCity
                      activeArrowTab={activeArrowTab}
                      animatedComponents={animatedComponents}
                      cancelHandller={cancelHandller}
                      cityAndNight={cityAndNight}
                      cityOptions={cityOptions}
                      handleAddCity={handleAddCity}
                      modalSaveHandller={modalSaveHandller}
                      onChangeHandller={onChangeHandller}
                      selectOptionHandller={selectOptionHandller}
                      showInvalid={showInvalid}
                      toggleArrowTab={toggleArrowTab}
                      handleDragEnd={handleDragEnd}
                      removeCityHandler={removeCityHandler}
                    />
                  </TabPane>

                  {/* <TabPane id="steparrow-cityandnight-info" tabId={3}>
                    <PackageSiteSeeing
                      dataSource={dataSourceSite}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      activeArrowTab={activeArrowTab}
                      toggleArrowTab={toggleArrowTab}
                    />
                  </TabPane> */}
                  {/* <TabPane id="steparrow-transport-info" tabId={4}>
                    <PackageTransportRate
                      activeArrowTab={activeArrowTab}
                      animatedComponents={animatedComponents}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      selectOptionHandller={selectOptionHandller}
                      showInvalid={showInvalid}
                      toggleArrowTab={toggleArrowTab}
                      transport={transport}
                      transportList={transportList}
                      transportOptions={transportOptions}
                    />
                  </TabPane> */}
                  <TabPane id="steparrow-inclusions-info" tabId={5}>
                    {/**Package inclusions*/}
                    <PackageInclusions
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      handleAddInclusions={handleAddInclusions}
                      inclusions={inclusions}
                      modalSaveHandller={modalSaveHandller}
                      onChangeHandller={onChangeHandller}
                      removeInclustionsHandller={removeInclustionsHandller}
                      showInvalid={showInvalid}
                      toggleArrowTab={toggleArrowTab}
                      type={"inclusions"}
                      handleDragEndInclusions={handleDragEndInclusions}
                    />
                  </TabPane>
                  <TabPane id="steparrow-exclusions-info" tabId={6}>
                    {/**Package inclusions*/}
                    <PackageExlusions
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      handleAddInclusions={handleAddExclusions}
                      exclusions={exclusions}
                      modalSaveHandller={modalSaveHandller}
                      onChangeHandller={onChangeHandller}
                      removeExclusionsHandller={removeExclusionsHandller}
                      showInvalid={showInvalid}
                      toggleArrowTab={toggleArrowTab}
                      type={"exclusions"}
                      handleDragEndInclusions = {handleDragEndExclusions}
                    />
                  </TabPane>
                  <TabPane id="steparrow-image-info" tabId={7}>
                    <PackageImages
                      toggleArrowTab={toggleArrowTab}
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                                                             
                      handleAcceptedCoverImage = {handleAcceptedCoverImage}
                      handleAcceptedProfileImage = {handleAcceptedProfileImage}
                      handleAcceptedGalleryImages={handleAcceptedGalleryImages}

                      selectedCoverImage = {selectedCoverImage}
                      selectedProfileImage = {selectedProfileImage}
                      selectedGalleryImages={selectedGalleryImages}

                      savedCoverImage = {savedCoverImage}
                      savedProfileImage = {savedProfileImage} 
                      savedGalleryImages = {savedGalleryImages} 

                     // removeSelectedImages={removeSelectedImages}
                      //removeSelectedPrimaryImage = {removeSelectedPrimaryImage}    

                      //onPrimaryImageDeleteHandller = {onPrimaryImageDeleteHandller}
                      //onGalleryImageDeleteHandller={onGalleryImageDeleteHandller}

                      removeSelectedCoverImage ={removeSelectedCoverImage}
                      removeSelectedProfileImage ={removeSelectedProfileImage}
                      removeSelectedGalleryImage ={removeSelectedGalleryImage} 
                    
                      removeSavedCoverImage ={removeSavedCoverImage}
                      removeSavedProfileImage ={removeSavedProfileImage}
                      removeSavedGalleryImage ={removeSavedGalleryImage}
                    />
                  </TabPane>
                  <TabPane id="steparrow- itinerary-info" tabId={8}>
                    <PackageItinerary
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      handleAddItinerary={handleAddItinerary}
                      modalSaveHandller={modalSaveHandller}
                      onChangeHandller={onChangeHandller}
                      packageItinerary={packageItinerary}
                      removeItineraryHandller={removeItineraryHandller}
                      showInvalid={showInvalid}
                      toggleArrowTab={toggleArrowTab}
                     // onChangeEditorHandller={onChangeEditorHandller}
                      onChangeQuillEditorHandller={onChangeQuillEditorHandller}
                      onChangeQuillEditorHandllerNew={onChangeQuillEditorHandllerNew}
                    />
                  </TabPane>

                  <TabPane id="steparrow- dates-info" tabId={11}>
                    {/* <PackageDates
                      //onChangeEditorHandller={onChangeEditorHandller}
                      onChangeQuillEditorHandller={onChangeQuillEditorHandller}
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      toggleArrowTab={toggleArrowTab}
                      packageDates={packageDates}
                    /> */}

                    <PackageDatesDynamic
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      handleAddPackageDates={handleAddPackageDates}
                      packageDatesList={packageDatesList}
                      modalSaveHandller={modalSaveHandller}
                      onChangeDateTimePicker={onChangeDateTimePicker}
                      onChangePackageDatesInputs={onChangePackageDatesInputs}
                      removePackageDatesHandller={removePackageDatesHandller}
                      showInvalid={showInvalid}
                      toggleArrowTab={toggleArrowTab}
                      handleDragEndpackageDates={handleDragEndpackageDates}
                    />

                    {/* <QuillTextEditor                      
                      onChangeQuillEditorHandller={onChangeQuillEditorHandller}
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      toggleArrowTab={toggleArrowTab}
                      editorHeader = {"Package Dates"}
                      editorName = {"packageDates"}
                      editorHTML={packageDates}
                    /> */}

                  </TabPane>

                  <TabPane id="steparrow- rates-info" tabId={13}>
                    {/* <PackageRates
                      onChangeQuillEditorHandller={onChangeQuillEditorHandller}
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      toggleArrowTab={toggleArrowTab}
                      packageRates={packageRates}
                    /> */}

                <PackageRatesDynamic
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      handleAddPackageRates={handleAddPackageRates}
                      packageRatesList={packageRatesList}
                      modalSaveHandller={modalSaveHandller}         
                      onChangePackageRatesInputs={onChangePackageRatesInputs}
                      removePackageRatesHandller={removePackageRatesHandller}
                      showInvalid={showInvalid}
                      toggleArrowTab={toggleArrowTab}
                      handleDragEndpackageRates={handleDragEndpackageRates}
                    />


                    {/* <QuillTextEditor                      
                      onChangeQuillEditorHandller={onChangeQuillEditorHandller}
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      toggleArrowTab={toggleArrowTab}
                      editorHeader = {"Package Dates"}
                      editorName = {"packageDates"}
                      editorHTML={packageDates}
                    /> */}

                  </TabPane>

                  <TabPane id="steparrow- guidelines-info" tabId={9}>
                    <PackageGuideLines
                      //onChangeEditorHandller={onChangeEditorHandller}
                      onChangeQuillEditorHandller={onChangeQuillEditorHandller}
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      toggleArrowTab={toggleArrowTab}
                      packageGuidelines={packageGuidelines}
                    />

                    {/* <QuillTextEditor                  
                      onChangeQuillEditorHandller={onChangeQuillEditorHandller}
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      toggleArrowTab={toggleArrowTab}
                      editorHeader = {"Package Guidelines"}
                      editorName = {"packageGuidelines"}
                      editorHTML={packageGuidelines}
                    /> */}

                  </TabPane>

                  <TabPane id="steparrow- why-us-info" tabId={10}>
                    <PackageWhyUs
                      //onChangeEditorHandller={onChangeEditorHandller}
                      onChangeQuillEditorHandller={onChangeQuillEditorHandller}
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      toggleArrowTab={toggleArrowTab}
                      packageWhyus={packageWhyus}
                    />

                    {/* <QuillTextEditor                    
                      onChangeQuillEditorHandller={onChangeQuillEditorHandller}
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      toggleArrowTab={toggleArrowTab}
                      editorHeader = {"Package Why Us"}
                      editorName = {"packageWhyus"}
                      editorHTML={packageWhyus}
                    /> */}

                  </TabPane>

                  <TabPane id="steparrow- why-us-info" tabId={12}>
                      <PackageFaqComponent
                          handleAddFaq={handleAddFaq}
                          packageFAQ={packageFAQ}
                          activeArrowTab={activeArrowTab}
                          cancelHandller={cancelHandller}                        
                          modalSaveHandller={modalSaveHandller}
                          onChangeHandller={onChangeHandller}                     
                          removeFaqHandller={removeFaqHandller}
                          showInvalid={showInvalid}
                          toggleArrowTab={toggleArrowTab}
                       //   onChangeEditorHandller={onChangeEditorHandller}
                          onChangeQuillEditorHandller={onChangeQuillEditorHandller}
                          handleDragEndFaq={handleDragEndFaq}

                        />
                  </TabPane>

                </TabContent>
                </Col>
                </Row>
                 
                </div>

             
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Modal>

 
      <Modal
        isOpen={modalDuplicatePackage}
        toggle={() => {
          toggleModalDuplicatePackage();
        }}
        centered
      >
        <ModalHeader className="modal-title" />
        <ModalBody className="text-center p-5">
          {/* <i className="ri-file-copy-2-fill fs-24"> </i>    */}
          <div className="mt-4">
            <h4 className="mb-3">Are you sure you want to Duplicate Package "{duplicatePackageName}"?</h4>
            <div className="hstack gap-2 justify-content-center">
              <Button color="danger" onClick={toggleModalDuplicatePackage}>
                No
              </Button>
              <Button color="primary" onClick={duplicatePackageHandller} >
                Yes
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>


    </>
  );
};

export default Packages;
