import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { publicRoutes } from "./allRoutes";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Layout from "../Layouts";
import Package from "../pages/Package";
import Signup from "../pages/Register";
import PasswordReset from "../pages/ForgotPassword";
import CreatePassword from "../pages/CreatePassword";
import Logout from "../pages/Logout";
import Destination from "../pages/Masters/Destination";
import City from "../pages/Masters/City";
import Hotel from "../pages/Masters/Hotel";
import HotelRooms from "../pages/Masters/HotelRooms";
import Transport from "../pages/Masters/Transport";
import TransportRate from "../pages/Masters/TransportRate";
import CitySiteSeeing from "../pages/Masters/CitySiteSeeing";
import PickupDrop from "../pages/Masters/PickupDrop";
import PickupDropLocation from "../pages/Masters/PickupDropLocation";

import RegistrationSuccess from "../pages/RegisteredSuccessfull";
import UploadFiles from "../pages/MarketingMenu/UploadFiles";
import SendWhatsapp from "../pages/MarketingMenu/SendWhatsapp";
import SendEmail from "../pages/MarketingMenu/SendEmail";
import ForgotSuccess from "../pages/ForgotSuccess";
import ForgotPasswordScreen from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import ChangePasswordSuccess from "../pages/ChangePasswordSuccess";
import ProfileSettings from "../pages/ProfileSettings";
import DomesticPackage from "../pages/Package/DomesticPackage";
import InternationalPackage from "../pages/Package/InternationalPackage";
import Packages from "../pages/Package/Packages";

import UpdateTravellingCompany from "../pages/MarketingMenu/UpdateTravellingCompany";
import Processlogs from "../pages/Logs/Processlogs";
import VerifyEmail from "../pages/VerifyEmail";

import ImportSetup from "../pages/Masters/ImportSetup/ImportSetup";
import ImportDestinations from "../pages/Masters/Destination/ImportDestinations";
import ImportCity from "../pages/Masters/City/ImportCity";
import ImportCitySiteSeeing from "../pages/Masters/CitySiteSeeing/ImportCitySiteSeeing";
import ImportTransportRate from "../pages/Masters/TransportRate/ImportTransportRate";
import ImportHotelRooms from "../pages/Masters/HotelRooms/ImportHotelRooms";
import ImportPackage from "../pages/Package/ImportPackage";
import PageNotFound from "../pages/PageNotFound/PageNotFound";

import Inquiry from "../pages/Inquiry/Inquiry";
import InquiryProcess from "../pages/Inquiry/InquiryProcess";
import InquiryProcess3 from "../pages/Inquiry/InquiryProcess3";
import Slide from "../Layouts/Home/Slide";
import HomeSettings from "../pages/Home/Settings";

const AllRoutes = () => {
  /**This is for session storage transfer for all tabs */
  // useEffect(() => {
  //   var sessionStorage_transfer = function (event) {
  //     if (!event) {
  //       event = window.event;
  //     } // ie suq
  //     if (!event.newValue) return; // do nothing if no value to work with
  //     if (event.key == "getSessionStorage") {
  //       // another tab asked for the sessionStorage -> send it
  //       localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
  //       // the other tab should now have it, so we're done with it.
  //       localStorage.removeItem("sessionStorage"); // <- could do short timeout as well.
  //     } else if (event.key == "sessionStorage" && !sessionStorage.length) {
  //       // another tab sent data <- get it
  //       var data = JSON.parse(event.newValue);
  //       for (var key in data) {
  //         sessionStorage.setItem(key, data[key]);
  //       }
  //     }
  //   };

  //   // listen for changes to localStorage
  //   if (window.addEventListener) {
  //     window.addEventListener("storage", sessionStorage_transfer, false);
  //   } else {
  //     window.attachEvent("onstorage", sessionStorage_transfer);
  //   }

  //   // Ask other tabs for session storage (this is ONLY to trigger event)
  //   if (!sessionStorage.length) {
  //     localStorage.setItem("getSessionStorage", "foobar");
  //     localStorage.removeItem("getSessionStorage", "foobar");
  //   }
  // }, []);

  return (
    <>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route element={<PageNotFound />} />
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPasswordScreen />} />
        <Route path="/createpassword" element={<CreatePassword />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/registrationsuccess" element={<RegistrationSuccess />} />
        <Route path="/forgotsuccess" element={<ForgotSuccess />} />
        <Route path="/verifyUserEmail" element={<VerifyEmail />} />

        <Route path="/resetpassword" element={<ResetPassword />} />

        <Route path="/home" element={<Layout />} />

        <Route
          path="/passwordchangesuccess"
          element={<ChangePasswordSuccess />}
        />
          <Route path="/" element={<Layout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/processlogs" element={<Processlogs />} />
          {/* <Route
            path="/customize-domestic-package"
            element={<DomesticPackage />}
          />
          <Route
            path="/customize-international-package"
            element={<InternationalPackage />}
          /> */}
          <Route
            path="/international-packages"
            element={<InternationalPackage />}
          />

          <Route
            path="/domestic-packages"
            element={<DomesticPackage />}
          />

          <Route
            path="/packages"
            element={<Packages />}
          />


          {/* <Route
            path="/globalmasters-fixed-package"
            element={<FixedPackage />}
          /> */}
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/globalmasters-destination" element={<Destination />} />
          <Route path="/globalmasters-pickupdrop" element={<PickupDrop />} />
          <Route path="/pickupDropLocation" element={<PickupDropLocation />} />

          <Route path="/globalmasters-city" element={<City />} />
          <Route path="/hotels" element={<Hotel />} />

          <Route path="/destinations" element={<Destination />} />

          <Route path="/globalmasters-hotel-rooms" element={<HotelRooms />} />
          <Route path="/transports" element={<Transport />} />
          <Route path="/cityAndSiteSeeing" element={<CitySiteSeeing />} />
          <Route
            path="/globalmasters-transport-rate"
            element={<TransportRate />}
          />
          <Route path="/uploadfiles" element={<UploadFiles />} />
          <Route path="/sendwhatsapp" element={<SendWhatsapp />} />
          <Route path="/sendemail" element={<SendEmail />} />
          <Route path="/profilesettings" element={<ProfileSettings />} />
          <Route
            path="/updatetravellingcompany"
            element={<UpdateTravellingCompany />}
          />
          <Route path="/importSetup" element={<ImportSetup />} />
          <Route path="/importDestinations" element={<ImportDestinations />} />
          <Route path="/importCity" element={<ImportCity />} />
          <Route path="/importCitySiteSeeing" element={<ImportCitySiteSeeing />} />
          <Route path="/importTransportRate" element={<ImportTransportRate />} />
          <Route path="/importHotelRooms" element={<ImportHotelRooms />} />
          <Route path="/importPackage" element={<ImportPackage />} />


          <Route path="/city" element={<City />} />
          <Route path="/citySiteSeeing" element={<CitySiteSeeing />} />
          <Route path="/transportRate" element={<TransportRate />} />
          <Route path="/hotelRooms" element={<HotelRooms />} />


          <Route path="/Inquiry" element={<Inquiry />} />
          <Route path="/InquiryProcess" element={<InquiryProcess />} />

          {/* <Route path="/InquiryProcess/:currentActiveStatusTab" component={InquiryProcess} /> */}


          {/* <Route path="/InquiryProcess2" element={<InquiryProcess2 />} /> */}
          <Route path="/InquiryProcess3" element={<InquiryProcess3 />} />

          <Route path="/Slide" element={<Slide />} />
          <Route path="/homesettings" element={<HomeSettings />} />
          
          </Route>
      </Routes>
    </>
  );
};

export default AllRoutes;
