import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Input, Label, Row, Col,  Card, CardBody, CardHeader   } from "reactstrap";
import { url } from "../../../services/common/const";
import ButttonTravelNinjaz from "../../Common/GloablMaster/ButttonTravelNinjaz";
// Import React FilePond

const HeroImages = ({
  toggleArrowTab,
  activeArrowTab,
  cancelHandller,
  modalSaveHandller,
  
  handleAcceptedCoverImage,
  handleAcceptedProfileImage,

  selectedCoverImage,
  selectedProfileImage,

  savedCoverImage,
  savedProfileImage,

  removeSelectedCoverImage,
  removeSelectedProfileImage,

  removeSavedCoverImage,
  removeSavedProfileImage,

}) => {
  return (
    <>
      {" "}
      <Row>
              <Col lg={12}>
                  <Card>
                    <CardHeader className="align-items-center d-flex bg-light">
                      <h4 className="card-title mb-0">Package Images</h4>
                    </CardHeader>
                    <CardBody>
                      <Row>

                      <div className="col-6">
                              <Dropzone
                                id="dropZonePrimaryImage"
                                name="dropZonePrimaryImage"
                                onDrop={(acceptedFiles) => {
                                  //handleResizePrimaryFile(e.target.files[0]);
                                  handleAcceptedCoverImage(acceptedFiles);
                                }}
                                accept="image/jpeg,image/png,image/gif,image/jpg"
                                multiple = {false}
                              >
                              
                                  {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone dz-clickable dropzonecustom">
                                    {/* <div class="text-center material-shadow alert alert-secondary fade show" role="alert">  
                                    Note: Please save the Cover Image in the   <strong> (1520 x 660) </strong>      dimenstion                         
                                    </div>   */}

                                    <div className="dz-message needsclick" {...getRootProps()}>
                                      <div className="mb-3">
                                        <i className="display-6 text-muted ri-upload-cloud-2-fill" />
                                      </div>
                                      <h6>Upload Cover Image (1520 x 660) </h6>                                           
                                    </div>  

                                  </div>
                                  
                                )}
                              </Dropzone>
                              <hr />
                              <p>Selected Cover Image</p>
                        
                              

                                  {selectedCoverImage != null && (

                                      <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={1 + "-file"}
                                      >
                                      <div className="p-2">
                                      <Row className="align-items-center">
                                      <Col xxl={2}>
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={selectedCoverImage?.name}
                                            src={selectedCoverImage?.preview}
                                          />
                                        </Col>
                                        <Col xxl={9}>
                                          <Link to="#" className="text-muted font-weight-bold">
                                            {selectedCoverImage?.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{selectedCoverImage?.formattedSize}</strong>
                                          </p>
                                        </Col>
                                        <Col xxl={1}>
                                          <FeatherIcon
                                            icon="x"
                                            style={{
                                              color: "#364574",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => removeSelectedCoverImage(selectedCoverImage)}
                                          />
                                        </Col>
                                      </Row>
                                      </div>
                                      </Card>    
                                    )}
                                                          

                              <hr />
                               <div className="list-unstyled mb-0" id="file-previews">
                                <p>Saved Cover Image</p>
                               
                                      {savedCoverImage != null && savedCoverImage != "" && (

                                        <Card
                                        className="mt-1 mb-0 shadow-none border d-flex dz-processing dz-image-preview dz-success dz-complete"
                                        key={2 + "-file"}
                                        >
                                        <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto" xxl={2}>
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={"image"}
                                              src={`${url}/Images/Package/${savedCoverImage?.package_id}/${savedCoverImage.image_name}`}
                                            />
                                          </Col>   
                                          <Col xxl={9}>
                                          <Link to="#" className="text-muted font-weight-bold">
                                            {savedCoverImage.image_name}
                                          </Link>
                                         
                                          </Col>

                                          <Col xxl={1}>
                                            <FeatherIcon
                                              icon="trash-2"
                                              style={{
                                                color: "#364574",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => removeSavedCoverImage(savedCoverImage)}
                                            />
                                          </Col>
                                        </Row>
                                        </div>
                                        </Card>
                                           )}
                                   
                              </div>                        
                            </div>
                            <div className="col-6">
                              <Dropzone
                                id="dropZonePrimaryImage"
                                name="dropZonePrimaryImage"
                                onDrop={(acceptedFiles) => {                       
                                  handleAcceptedProfileImage(acceptedFiles);
                                }}
                                accept="image/jpeg,image/png,image/gif,image/jpg"
                                multiple = {false}
                              >
                              
                              {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone dz-clickable dropzonecustom">
                                    {/* <div class="text-center material-shadow alert alert-secondary fade show" role="alert">  
                                    Note: Please save the Primary Image in the   <strong> (1155 x 765) </strong>      dimenstion                         
                                    </div> */}

                                    <div className="dz-message needsclick" {...getRootProps()}>
                                      <div className="mb-3">
                                        <i className="display-6 text-muted ri-upload-cloud-2-fill" />
                                      </div>
                                      <h6>Upload Profile Image (460 x 460) </h6>                                           
                                    </div>  

                                  </div>
                                  
                                )}
                              </Dropzone>
                              <hr />
                              <p>Selected Profile Image</p>

                            
                                   {selectedProfileImage != null && (

                                  <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={1 + "-file"}
                                  >
                                  <div className="p-2">
                                      <Row className="align-items-center">
                                      <Col xxl={2}>
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={selectedProfileImage?.name}
                                            src={selectedProfileImage?.preview}
                                          />
                                        </Col>
                                        <Col xxl={9}>
                                          <Link to="#" className="text-muted font-weight-bold">
                                            {selectedProfileImage?.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{selectedProfileImage?.formattedSize}</strong>
                                          </p>
                                        </Col>
                                        <Col xxl={1}>
                                          <FeatherIcon
                                            icon="x"
                                            style={{
                                              color: "#364574",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => removeSelectedProfileImage(selectedProfileImage)}
                                          />
                                        </Col>
                                      </Row>
                                      </div>
                                      </Card>    
                                    )}
                                    
                              <hr />
                               <div className="list-unstyled mb-0" id="file-previews">
                                <p>Saved Profile Image</p>
                             
                             

                                      {savedProfileImage!= null  && savedProfileImage != ""  && (

                                      <Card
                                      className="mt-1 mb-0 shadow-none border d-flex dz-processing dz-image-preview dz-success dz-complete"
                                      key={2 + "-file"}
                                      >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto" xxl={2}>
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={"image"}
                                              src={`${url}/Images/Package/${savedProfileImage?.package_id}/${savedProfileImage.image_name}`}
                                            />
                                          </Col>   
                                          <Col xxl={9}>
                                          <Link to="#" className="text-muted font-weight-bold">
                                            {savedProfileImage.image_name}
                                          </Link>
                                         
                                          </Col>

                                          <Col xxl={1}>
                                            <FeatherIcon
                                              icon="trash-2"
                                              style={{
                                                color: "#364574",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => removeSavedProfileImage(savedProfileImage)}
                                            />
                                          </Col>
                                        </Row>
                                        </div>
                                        </Card>
                                           )}
                                   

                              </div>                        
                            </div>
                      </Row>
<hr/>
                   
                    </CardBody>
                  </Card>
              </Col>   
          
      </Row>        


    
     
      <div className="d-flex justify-content-xl-between my-3">
        <div>
          <button
            type="button"
            className="btn btn-light btn-label previestab"
            onClick={() => {
              toggleArrowTab(activeArrowTab - 1);
            }}
          >
            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
            Back
          </button>
        </div>
        <div>
          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
            className="mx-1"
          />
          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
            className="mx-1"
          />
        </div>
      </div>
    </>
  );
};

export default HeroImages;
